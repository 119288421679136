import React, { useState } from "react";
import { Formik } from "formik";
import Modal from "@mui/material/Modal";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Grid, Card, Box } from "@mui/material";
import MDBox from "components/MDBox";
import Input from "components/molecules/input/input";
import Icon from "@mui/material/Icon";
import { AddRepresentativesSchema } from "helpers/schemas/onboardingSchemas";
import { isMobile } from "../../../../utils/isMobile";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isMobile() ? "98%" : "50%",
  p: 4,
};

function CompanyOnboardingStep3() {
  const [openModal, setOpenModal] = useState(false);
  const [representatives, setRepresentatives] = useState([]);
  const [isEditModal, setIsEditModal] = useState(false);
  const [currentRepresentative, setCurrentRepresentative] = useState({});

  const addRepresentative = (values) => {
    setRepresentatives([...representatives, values]);
    handleCloseModal();
  };
  const onEditRepresentative = (index) => {
    setIsEditModal(true);
    const currentRepresentative = { ...representatives[index], id: index };
    setCurrentRepresentative(currentRepresentative);
    setOpenModal(true);
  };

  const updateRepresentative = (values) => {
    let array = representatives;
    array[currentRepresentative.id] = values;
    setRepresentatives(array);
    setIsEditModal(false);
    setOpenModal(false);
  };

  const onDeleteRepresentative = (index) => {
    const currentRepresentative = { ...representatives[index], id: index };
    let array = representatives.filter(
      (representative) => representative.name !== currentRepresentative.name
    );
    setRepresentatives(array);
    setOpenModal(false);
  };

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => {
    setOpenModal(false);
    setIsEditModal(false);
  };

  const initialValuesDecider = () => {
    if (isEditModal) {
      return {
        id: currentRepresentative.id,
        name: currentRepresentative.name,
        surname: currentRepresentative.surname,
        cuit: currentRepresentative.cuit,
        address: currentRepresentative.address,
        position: currentRepresentative.position,
        positionFile: currentRepresentative.positionFile,
        dniFile: currentRepresentative.dniFile,
      };
    } else {
      return {
        id: "",
        name: "",
        surname: "",
        cuit: "",
        address: "",
        position: "",
        positionFile: "",
        dniFile: "",
      };
    }
  };

  return (
    <>
      <Grid container mt={3} mb={5}>
        <Grid item xs={12}>
          <MDTypography variant="h6" fontWeight="medium" color="dark">
            Representante/s de la empresa
          </MDTypography>
        </Grid>
        <Grid item mt={2} xs={12}>
          <MDButton
            variant="gradient"
            color="dark"
            size="small"
            onClick={handleOpenModal}
          >
            + Agregar representante
          </MDButton>
        </Grid>

        <Grid item xs={12} sm={5}>
          {representatives.length > 0
            ? representatives.map((representative, index) => (
                <MDBox
                  component="li"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  bgColor={"grey-100"}
                  borderRadius="lg"
                  p={3}
                  mb={0}
                  mt={2}
                  key={index}
                >
                  <MDBox width="100%" display="flex" flexDirection="column">
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems={{ xs: "flex-start", sm: "center" }}
                      flexDirection={{ xs: "column", sm: "row" }}
                      mb={1}
                    >
                      <MDTypography
                        variant="button"
                        fontWeight="medium"
                        textTransform="capitalize"
                      >
                        {representative.name}&nbsp;
                        {representative.surname}
                      </MDTypography>
                      <MDBox
                        display="flex"
                        alignItems="center"
                        mt={{ xs: 2, sm: 0 }}
                        ml={{ xs: -1.5, sm: 0 }}
                      >
                        <MDBox mr={1}>
                          <MDButton
                            variant="text"
                            color={"dark"}
                            onClick={() => onEditRepresentative(index)}
                          >
                            <Icon>edit</Icon>&nbsp;{!isMobile() && "Editar"}
                          </MDButton>
                        </MDBox>
                        <MDButton
                          variant="text"
                          color="error"
                          onClick={() => onDeleteRepresentative(index)}
                        >
                          <Icon>delete</Icon>&nbsp;{!isMobile() && "Eliminar"}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                    <MDBox mb={1} lineHeight={0}>
                      <MDTypography variant="caption" color="text">
                        CUIT Nº:&nbsp;&nbsp;&nbsp;
                        <MDTypography
                          variant="caption"
                          fontWeight="medium"
                          textTransform="capitalize"
                        >
                          {representative.cuit}
                        </MDTypography>
                      </MDTypography>
                    </MDBox>
                    <MDBox mb={1} lineHeight={0}>
                      <MDTypography variant="caption" color="text">
                        Domicilio:&nbsp;&nbsp;&nbsp;
                        <MDTypography variant="caption" fontWeight="medium">
                          {representative.address}
                        </MDTypography>
                      </MDTypography>
                    </MDBox>
                    <MDTypography variant="caption" color="text">
                      Ocupación:&nbsp;&nbsp;&nbsp;
                      <MDTypography variant="caption" fontWeight="medium">
                        {representative.position}
                      </MDTypography>
                    </MDTypography>
                  </MDBox>
                </MDBox>
              ))
            : null}
        </Grid>
      </Grid>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Formik
          initialValues={initialValuesDecider()}
          validationSchema={AddRepresentativesSchema}
          validateOnChange={true}
          onSubmit={isEditModal ? updateRepresentative : addRepresentative}
        >
          {({ errors, values, setFieldValue, handleSubmit, touched }) => (
            <Card sx={style}>
              <MDTypography variant="h5" fontWeight="medium" color="dark">
                {isEditModal ? "Editar representante" : "Agregar representante"}
              </MDTypography>
              <Grid container mt={1} spacing={2}>
                <Grid item container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Input
                      type={"text"}
                      label={"Nombre"}
                      fullWidth={true}
                      onChange={(e) => setFieldValue("name", e.target.value)}
                      value={values.name}
                      error={!!errors.name && touched.name}
                      errorText={errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      type={"text"}
                      label={"Apellido"}
                      fullWidth={true}
                      onChange={(e) => setFieldValue("surname", e.target.value)}
                      value={values.surname}
                      error={!!errors.surname && touched.surname}
                      errorText={errors.surname}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    type={"text"}
                    label={"Cuit"}
                    fullWidth={true}
                    onChange={(e) => setFieldValue("cuit", e.target.value)}
                    value={values.cuit}
                    error={!!errors.cuit && touched.cuit}
                    errorText={errors.cuit}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    type={"text"}
                    label={"Domicilio"}
                    fullWidth={true}
                    onChange={(e) => setFieldValue("address", e.target.value)}
                    value={values.address}
                    error={!!errors.address && touched.address}
                    errorText={errors.address}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    type={"text"}
                    label={"Ocupación"}
                    fullWidth={true}
                    onChange={(e) => setFieldValue("position", e.target.value)}
                    value={values.position}
                    error={!!errors.position && touched.position}
                    errorText={errors.position}
                  />
                </Grid>
                <Grid item xs={12}>
                  <input
                    accept="*"
                    style={{ display: "none", marginBottom: "100px" }}
                    id="button-file-occupation"
                    multiple
                    type="file"
                    onChange={(e) => {
                      setFieldValue("positionFile", e.currentTarget.files[0]);
                    }}
                  />
                  <label htmlFor="button-file-occupation">
                    <MDBox
                      display="flex"
                      alignItems="center"
                      mb={1}
                      sx={{ cursor: "pointer" }}
                    >
                      <Icon fontSize="small">picture_as_pdf</Icon>
                      <MDTypography variant="button" fontWeight="bold">
                        &nbsp;Subir constancia de ocupación&nbsp;
                      </MDTypography>
                      <MDTypography variant="caption">
                        {values.positionFile?.name
                          ? values.positionFile.name
                          : null}
                      </MDTypography>
                      {errors?.positionFile && touched.positionFile ? (
                        <MDTypography variant="caption" color="error">
                          {errors.positionFile}
                        </MDTypography>
                      ) : null}
                    </MDBox>
                  </label>
                </Grid>
                <Grid item xs={12}>
                  <input
                    accept="*"
                    style={{ display: "none", marginBottom: "100px" }}
                    id="button-file-identity"
                    multiple
                    type="file"
                    onChange={(e) => {
                      setFieldValue("dniFile", e.currentTarget.files[0]);
                    }}
                  />
                  <label htmlFor="button-file-identity">
                    <MDBox
                      display="flex"
                      alignItems="center"
                      mb={2}
                      sx={{ cursor: "pointer" }}
                    >
                      <Icon fontSize="small">picture_as_pdf</Icon>
                      <MDTypography variant="button" fontWeight="bold">
                        &nbsp;Subir documento de identidad&nbsp;
                      </MDTypography>
                      <MDTypography variant="caption">
                        {values.dniFile?.name ? values.dniFile.name : null}
                      </MDTypography>
                      {errors?.dniFile && touched.dniFile ? (
                        <MDTypography variant="caption" color="error">
                          {errors.dniFile}
                        </MDTypography>
                      ) : null}
                    </MDBox>
                  </label>
                </Grid>
                <Grid item container justifyContent="space-between" xs={12}>
                  <Grid item>
                    <MDButton
                      variant="text"
                      color="info"
                      size="small"
                      onClick={handleCloseModal}
                    >
                      Cancelar
                    </MDButton>
                  </Grid>
                  <Grid item>
                    <MDButton
                      color="info"
                      size="small"
                      onClick={() => handleSubmit()}
                    >
                      {isEditModal ? "Aceptar" : "Agregar"}
                    </MDButton>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default CompanyOnboardingStep3;
