import * as React from "react";
import { Formik } from "formik";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";
import MDButton from "components/MDButton";
import StudentOnboardingStep1 from "./steps/studentOnboardingStep1";
import StudentOnboardingStep2 from "./steps/studentOnboardingStep2";
import StudentOnboardingStep3 from "./steps/studentOnboardingStep3";
import StudentOnboardingStep4 from "./steps/studentOnboardingStep4";
import StudentOnboardingStep5 from "./steps/studentOnboardingStep5";
import {
  StudentOnboardingStep1Schema,
  StudentOnboardingStep2Schema,
  StudentOnboardingStep3Schema,
  StudentOnboardingStep4Schema,
  StudentOnboardingStep5Schema,
} from "helpers/schemas/onboardingSchemas";
import { isMobile } from "../../../utils/isMobile";

const steps = [
  "Datos personales",
  "Datos de locación",
  "Datos académicos",
  "Datos de salud",
  "Antecedentes y conocimientos",
];

function StudentOnboarding() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [socialNetworksState, setSocialNetworksState] = React.useState({
    instagram: false,
    facebook: false,
    twitter: false,
    linkedin: false,
  });

  const handleChangeSocialNetworks = (event) => {
    setSocialNetworksState({
      ...socialNetworksState,
      [event.target.name]: event.target.checked,
    });
  };

  const socialNetworks = () => {
    let array = [];
    for (const socialNetwork in socialNetworksState) {
      if (socialNetworksState[socialNetwork] === true) {
        array.push(socialNetwork);
      }
    }
    return array;
  };

  const onSubmit = async (values) => {
    handleNext();
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const initialValuesDecider = () => {
    return {
      cuil: "27-41000729-6",
      gender: "",
      civilStatus: "",
      birthdate: "1998-06-10",
      phone: "15655487",
      address: "Suipacha	614",
      city: "San Francisco",
      province: "Córdoba",
      postalCode: "",
      isOwner: false,
      highSchoolName: "",
      highSchoolDegree: "",
      highSchoolCity: "",
      highSchoolProvince: "",
      highSchoolPostalCode: "",
      careerId: "1",
      approvedSubjectAmount: "42",
      startYear: "",
      currentCareerYear: "",
      hasHealthInsurance: false,
      healthInsurance: "",
      bloodType: "",
      bloodDonor: "",
      personalMotivation: "",
    };
  };

  const schemaDecider = () => {
    if (activeStep === 0) {
      return StudentOnboardingStep1Schema;
    }
    if (activeStep === 1) {
      return StudentOnboardingStep2Schema;
    }
    if (activeStep === 2) {
      return StudentOnboardingStep3Schema;
    }
    if (activeStep === 3) {
      return StudentOnboardingStep4Schema;
    }
    if (activeStep === 4) {
      return StudentOnboardingStep5Schema;
    }
  };

  return (
    <Formik
      initialValues={initialValuesDecider()}
      validationSchema={schemaDecider}
      validateOnChange={true}
      onSubmit={onSubmit}
    >
      {({ errors, values, setFieldValue, handleSubmit, touched }) => (
        <Box p={3}>
          {!isMobile() && (
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step
                    key={label}
                    {...stepProps}
                    style={{ padding: "0 2rem 0.5rem" }}
                  >
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          )}
          {activeStep === steps.length ? (
            <React.Fragment>
              <MDTypography
                variant="h4"
                fontWeight="medium"
                color="dark"
                mt={5}
                mb={2}
                textAlign="center"
              >
                Su registro ha sido finalizado
              </MDTypography>
              <Box
                sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                justifyContent="center"
              >
                <MDButton
                  variant="gradient"
                  color="success"
                  component={Link}
                  to="/student/dashboard"
                >
                  Ir al dashboard
                </MDButton>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {activeStep === 0 ? (
                <StudentOnboardingStep1
                  setFieldValue={setFieldValue}
                  errors={errors}
                  values={values}
                  touched={touched}
                  socialNetworksState={socialNetworksState}
                  onChangeSocialNetworks={handleChangeSocialNetworks}
                ></StudentOnboardingStep1>
              ) : null}
              {activeStep === 1 ? (
                <StudentOnboardingStep2
                  setFieldValue={setFieldValue}
                  errors={errors}
                  values={values}
                  touched={touched}
                ></StudentOnboardingStep2>
              ) : null}
              {activeStep === 2 ? (
                <StudentOnboardingStep3
                  setFieldValue={setFieldValue}
                  errors={errors}
                  values={values}
                  touched={touched}
                ></StudentOnboardingStep3>
              ) : null}
              {activeStep === 3 ? (
                <StudentOnboardingStep4
                  setFieldValue={setFieldValue}
                  errors={errors}
                  values={values}
                  touched={touched}
                ></StudentOnboardingStep4>
              ) : null}
              {activeStep === 4 ? (
                <StudentOnboardingStep5
                  setFieldValue={setFieldValue}
                  errors={errors}
                  values={values}
                  touched={touched}
                ></StudentOnboardingStep5>
              ) : null}
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <MDButton
                  variant="text"
                  color="info"
                  size="small"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  Anterior
                </MDButton>
                <Box sx={{ flex: "1 1 auto" }} />

                {activeStep === steps.length - 1 ? (
                  <MDButton color="info" size="small" onClick={handleNext}>
                    Finalizar
                  </MDButton>
                ) : (
                  <MDButton
                    variant="outlined"
                    color="info"
                    size="small"
                    onClick={() => handleSubmit()}
                  >
                    Siguiente
                  </MDButton>
                )}
              </Box>
            </React.Fragment>
          )}
        </Box>
      )}
    </Formik>
  );
}

export default StudentOnboarding;
