/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import MDBadge from "../../../components/MDBadge";
import React from "react";
import Grid from "@mui/material/Grid";

function ProfilesList({ title, profiles, shadow }) {
  const renderProfiles = profiles.map(
    ({ name, badgeContent, hasApplied, email, career, action1, action2 }) => (
      <MDBox
        key={name}
        component="li"
        display="flex"
        alignItems="center"
        py={1}
        mb={1}
      >
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <MDTypography variant="button" fontWeight="medium">
            {name}
            <MDBadge
              badgeContent={badgeContent}
              color="warning"
              variant="contained"
              size="md"
            />
            {hasApplied && (
              <MDBadge
                badgeContent="Ha aplicado a la oferta"
                color="success"
                variant="contained"
                size="md"
              />
            )}
          </MDTypography>
          <MDTypography variant="caption" color="text" mt={1}>
            <b>Email:</b> {email}
          </MDTypography>
          <MDTypography variant="caption" color="text">
            <b>Carrera:</b> {career}
          </MDTypography>
        </MDBox>
        <MDBox ml="auto">
          <MDButton
            component="a"
            href={action1.route}
            target="_blank"
            rel="noreferrer"
            variant="text"
            color={action1.color}
          >
            {action1.label}
          </MDButton>
          <MDButton
            component="a"
            href={action2.route}
            target="_blank"
            rel="noreferrer"
            size={"small"}
            color={action2.color}
          >
            {action2.label}
          </MDButton>
        </MDBox>
      </MDBox>
    )
  );

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderProfiles}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfilesList
ProfilesList.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
ProfilesList.propTypes = {
  title: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  shadow: PropTypes.bool,
};

export default ProfilesList;
