import * as React from "react";
import { Formik } from "formik";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";
import MDButton from "components/MDButton";
import CompanyOnboardingStep1 from "./steps/companyOnboardingStep1";
import CompanyOnboardingStep2 from "./steps/companyOnboardingStep2";
import CompanyOnboardingStep3 from "./steps/companyOnboardingStep3";
import { CompanyOnboardingStep1Schema } from "helpers/schemas/onboardingSchemas";
import { CompanyOnboardingStep2Schema } from "helpers/schemas/onboardingSchemas";
import companyOnboarding from "../../../services/api/company/companyOnboarding";
import { isMobile } from "../../../utils/isMobile";

const steps = [
  "Datos fiscales",
  "Datos de locación y contacto",
  "Representante/s de la empresa",
];

function CompanyOnboarding() {
  const [activeStep, setActiveStep] = React.useState(0);

  const onSubmit = async (values) => {
    const formData = new FormData();

    if (activeStep === 0) {
      formData.append("legalPersonType", values.legalPersonType);
      formData.append("taxStatus", values.taxStatus);
      formData.append("companyPurpose", values.companyPurpose);
      formData.append("file", values.file);
      const res1 = await companyOnboarding.step1(formData);
      if (res1.status === 201) {
        handleNext();
      }
    }
    if (activeStep === 1) {
      const res2 = await companyOnboarding.step2({
        realAddress: values.realAddress,
        legalAddress: values.legalAddress,
        phone: values.phone,
        whatsapp: values.whatsapp,
        companyEmail: values.companyEmail,
        location: {
          city: values.city,
          province: values.province,
          postalCode: values.postalCode,
        },
      });
      if (res2.status === 200) {
        handleNext();
      }
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const initialValues = () => {
    return {
      legalPersonType: "",
      taxStatus: "",
      companyPurpose: "",
      file: "",
      realAddress: "",
      legalAddress: "",
      city: "",
      province: "",
      postalCode: "",
      phone: "",
      whatsapp: "",
      website: "",
      companyEmail: "",
    };
  };

  const schemaDecider = () => {
    if (activeStep === 0) {
      return CompanyOnboardingStep1Schema;
    }
    if (activeStep === 1) {
      return CompanyOnboardingStep2Schema;
    }
  };

  return (
    <Formik
      initialValues={initialValues()}
      validationSchema={schemaDecider()}
      validateOnChange={true}
      onSubmit={onSubmit}
    >
      {({ errors, values, setFieldValue, handleSubmit, touched }) => (
        <Box p={3}>
          {!isMobile() && (
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                return (
                  <Step key={label} style={{ padding: "0 2rem 0.5rem" }}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          )}

          {activeStep === steps.length ? (
            <>
              <MDTypography
                variant="h4"
                fontWeight="medium"
                color="dark"
                mt={5}
                mb={2}
                textAlign="center"
              >
                Su registro ha sido finalizado
              </MDTypography>
              <Box
                sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                justifyContent="center"
              >
                <MDButton
                  variant="gradient"
                  color="success"
                  component={Link}
                  to="/company/dashboard"
                >
                  Ir al dashboard
                </MDButton>
              </Box>
            </>
          ) : (
            <>
              {activeStep === 0 ? (
                <CompanyOnboardingStep1
                  setFieldValue={setFieldValue}
                  errors={errors}
                  values={values}
                  touched={touched}
                ></CompanyOnboardingStep1>
              ) : null}
              {activeStep === 1 ? (
                <CompanyOnboardingStep2
                  setFieldValue={setFieldValue}
                  errors={errors}
                  values={values}
                  touched={touched}
                ></CompanyOnboardingStep2>
              ) : null}
              {activeStep === 2 ? <CompanyOnboardingStep3 /> : null}
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <MDButton
                  variant="text"
                  color="info"
                  size="small"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  Anterior
                </MDButton>
                <Box sx={{ flex: "1 1 auto" }} />

                {activeStep === steps.length - 1 ? (
                  <MDButton color="info" size="small" onClick={handleNext}>
                    Finalizar
                  </MDButton>
                ) : (
                  <MDButton
                    variant="outlined"
                    color="info"
                    size="small"
                    onClick={() => handleSubmit()}
                  >
                    Siguiente
                  </MDButton>
                )}
              </Box>
            </>
          )}
        </Box>
      )}
    </Formik>
  );
}

export default CompanyOnboarding;
