import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";
import Input from "components/molecules/input/input";

function CompanyOnboardingStep2({ setFieldValue, errors, values, touched }) {
  return (
    <Grid container mt={3}>
      <Grid item xs={12}>
        <MDTypography variant="h6" fontWeight="medium" color="dark">
          Datos de locación y contacto
        </MDTypography>
      </Grid>
      <Grid item mt={1} xs={12} md={7}>
        <Input
          type={"text"}
          label={"Domicilio real de la empresa"}
          fullWidth={true}
          onChange={(e) => setFieldValue("realAddress", e.target.value)}
          value={values.realAddress}
          error={!!errors.realAddress && touched.realAddress}
          errorText={errors.realAddress}
        />
        <Input
          type={"text"}
          label={"Domicilio legal de la empresa"}
          fullWidth={true}
          onChange={(e) => setFieldValue("legalAddress", e.target.value)}
          value={values.legalAddress}
          error={!!errors.legalAddress && touched.legalAddress}
          errorText={errors.legalAddress}
        />
      </Grid>
      <Grid item container xs={12} md={7} spacing={3}>
        <Grid item xs={12} md={4}>
          <Input
            type={"text"}
            label={"Localidad"}
            fullWidth={true}
            onChange={(e) => setFieldValue("city", e.target.value)}
            value={values.city}
            error={!!errors.city && touched.city}
            errorText={errors.city}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            type={"text"}
            label={"Provincia"}
            fullWidth={true}
            onChange={(e) => setFieldValue("province", e.target.value)}
            value={values.province}
            error={!!errors.province && touched.province}
            errorText={errors.province}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            type={"number"}
            label={"Código Postal"}
            fullWidth={true}
            onChange={(e) => setFieldValue("postalCode", e.target.value)}
            value={values.postalCode}
            error={!!errors.postalCode && touched.postalCode}
            errorText={errors.postalCode}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} md={7} spacing={3}>
        <Grid item xs={12} md={6}>
          <Input
            type={"text"}
            label={"Teléfono"}
            fullWidth={true}
            onChange={(e) => setFieldValue("phone", e.target.value)}
            value={values.phone}
            error={!!errors.phone && touched.phone}
            errorText={errors.phone}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type={"text"}
            label={"Whatsapp"}
            fullWidth={true}
            onChange={(e) => setFieldValue("whatsapp", e.target.value)}
            value={values.whatsapp}
            error={!!errors.whatsapp && touched.whatsapp}
            errorText={errors.whatsapp}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} md={7} spacing={3}>
        <Grid item xs={12} md={6}>
          <Input
            type={"text"}
            label={"Sitio web"}
            fullWidth={true}
            onChange={(e) => setFieldValue("website", e.target.value)}
            value={values.website}
            error={!!errors.website && touched.website}
            errorText={errors.website}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type={"email"}
            label={"Email de la empresa"}
            fullWidth={true}
            onChange={(e) => setFieldValue("companyEmail", e.target.value)}
            value={values.companyEmail}
            error={!!errors.companyEmail && touched.companyEmail}
            errorText={errors.companyEmail}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CompanyOnboardingStep2;
