// react-router-dom components
import { Link } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Grid } from "@mui/material";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

function Cover() {
  return (
    <BasicLayout image={bgImage}>
      <Grid item xs={12}>
        <MDBox py={2} lineHeight={0} textAlign="center">
          <MDTypography
            variant="h4"
            fontWeight="medium"
            color="dark"
            mt={1}
            mb={3}
          >
            Crear una nueva cuenta
          </MDTypography>
        </MDBox>
        <MDBox py={2}>
          <Grid container spacing={3} justifyContent="space-evenly">
            <Grid item container xs={6} justifyContent="right">
              <MDButton
                variant="gradient"
                color="success"
                component={Link}
                to="/auth/signup/company"
              >
                Soy una empresa
              </MDButton>
            </Grid>
            <Grid item xs={6}>
              <MDButton
                variant="gradient"
                color="info"
                component={Link}
                to="/auth/signup/student"
              >
                Soy un estudiante
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={4} mb={1} textAlign="center">
          <MDTypography variant="button" color="text">
            Ya tienes una cuenta?{" "}
            <MDTypography
              component={Link}
              to="/auth/signin"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Iniciar sesión
            </MDTypography>
          </MDTypography>
        </MDBox>
      </Grid>
    </BasicLayout>
  );
}

export default Cover;
