/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Images
import kal from "assets/images/kal-visuals-square.jpg";
import marie from "assets/images/marie.jpg";
import ivana from "assets/images/ivana-square.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

export default [
  {
    image: kal,
    name: "Sophie B.",
    badgeContent: "Aplica un 98% (ver más)",
    hasApplied: true,
    email: "sophie@gmail.com",
    career: "Ingeniería en Sistemas de Información",
    action1: {
      type: "internal",
      route: "/pages/profile/profile-overview",
      color: "dark",
      label: "Más información",
    },
    action2: {
      type: "internal",
      route: "/pages/profile/profile-overview",
      color: "dark",
      label: "Validar",
    },
  },
  {
    image: marie,
    name: "Anne Marie",
    badgeContent: "Aplica un 80%",
    email: "annemarie@gmail.com",
    career: "Tecnicatura en programación",
    action1: {
      type: "internal",
      route: "/pages/profile/profile-overview",
      color: "dark",
      label: "Más información",
    },
    action2: {
      type: "internal",
      route: "/pages/profile/profile-overview",
      color: "dark",
      label: "Informar sobre oferta",
    },
  },
  {
    image: ivana,
    name: "Ivanna",
    badgeContent: "Aplica un 77%",
    email: "ivanna@gmail.com",
    career: "Ingeniería en Sistemas de Información",
    action1: {
      type: "internal",
      route: "/pages/profile/profile-overview",
      color: "dark",
      label: "Más información",
    },
    action2: {
      type: "internal",
      route: "/pages/profile/profile-overview",
      color: "dark",
      label: "Informar sobre oferta",
    },
  },
];
