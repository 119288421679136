import React from "react";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

function StudentOnboardingStep2({ setFieldValue, errors, values, touched }) {
  return (
    <Grid container mt={3}>
      <Grid mt={3} item xs={12}>
        <MDTypography variant="h6" fontWeight="medium" color="dark">
          Datos de locación
        </MDTypography>
      </Grid>
      <Grid item mt={2} xs={12} md={7}>
        <MDBox mb={2}>
          <MDInput
            type="text"
            label="Domicilio"
            fullWidth
            value={values.address}
            onChange={(e) => setFieldValue("address", e.target.value)}
            error={!!errors.address}
          />
          {errors?.address ? (
            <MDTypography variant="caption" color="error">
              {errors.address}
            </MDTypography>
          ) : null}
        </MDBox>
        <MDBox>
          <RadioGroup
            row
            name="ownership-radio-buttons-group"
            value={values.isOwner}
            onChange={(e) => setFieldValue("isOwner", e.target.value)}
          >
            <FormControlLabel
              value={true}
              control={<Radio />}
              label="Es propietario"
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="Es inquilino"
            />
          </RadioGroup>
          {errors?.isOwner ? (
            <MDTypography variant="caption" color="error">
              {errors.isOwner}
            </MDTypography>
          ) : null}
        </MDBox>
      </Grid>
      <Grid item container mt={0} mb={2} spacing={3}>
        <Grid item xs={12} md={4}>
          <MDInput
            type="text"
            label="Localidad"
            fullWidth
            value={values.city}
            onChange={(e) => setFieldValue("city", e.target.value)}
            error={!!errors.city}
          />
          {errors?.city ? (
            <MDTypography variant="caption" color="error">
              {errors.city}
            </MDTypography>
          ) : null}
        </Grid>
        <Grid item xs={12} md={4}>
          <MDInput
            type="text"
            label="Provincia"
            fullWidth
            value={values.province}
            onChange={(e) => setFieldValue("province", e.target.value)}
            error={!!errors.province}
          />
          {errors?.province ? (
            <MDTypography variant="caption" color="error">
              {errors.province}
            </MDTypography>
          ) : null}
        </Grid>
        <Grid item xs={12} md={4}>
          <MDInput
            type="number"
            label="Código Postal"
            fullWidth
            value={values.postalCode}
            onChange={(e) => setFieldValue("postalCode", e.target.value)}
            error={!!errors.postalCode}
          />
          {errors?.postalCode ? (
            <MDTypography variant="caption" color="error">
              {errors.postalCode}
            </MDTypography>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default StudentOnboardingStep2;
