import Icon from "@mui/material/Icon";
import Dashboard from "../pages/admin/dashboard";
import JobOffers from "../pages/admin/jobOffers";

const adminRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "admin-dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Ofertas de pasantías",
    key: "job-offers",
    icon: <Icon fontSize="small">work</Icon>,
    route: "/dashboard/job-offers",
    component: <JobOffers />,
  },
];

export default adminRoutes;
