import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MDInput from "components/MDInput";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import Checkbox from "@mui/material/Checkbox";

function StudentOnboardingStep1({
  setFieldValue,
  errors,
  values,
  touched,
  socialNetworksState,
  onChangeSocialNetworks,
}) {
  return (
    <Grid container mt={3}>
      <Grid item xs={12}>
        <MDTypography variant="h4" fontWeight="medium" color="dark">
          Te damos la bienvenida
        </MDTypography>
        <MDTypography variant="subtitle2">
          Por favor, complete los siguientes datos para poder avanzar con su
          registro
        </MDTypography>
      </Grid>
      <Grid mt={3} item xs={12}>
        <MDTypography variant="h6" fontWeight="medium" color="dark">
          Datos personales
        </MDTypography>
      </Grid>
      <Grid item container mt={1} xs={12} spacing={3}>
        <Grid item xs={12} md={4}>
          <MDInput
            type="text"
            label="Cuil"
            fullWidth
            value={values.cuil}
            onChange={(e) => setFieldValue("cuil", e.target.value)}
            error={!!errors.cuil}
          />
          {errors?.cuil ? (
            <MDTypography variant="caption" color="error">
              {errors.cuil}
            </MDTypography>
          ) : null}
        </Grid>
        <Grid item xs={12} md={4}>
          <MDInput
            type="date"
            label="Fecha de nacimiento"
            fullWidth
            value={values.birthdate}
            onChange={(e) => setFieldValue("birthdate", e.target.value)}
            error={!!errors.birthdate}
          />
          {errors?.birthdate ? (
            <MDTypography variant="caption" color="error">
              {errors.birthdate}
            </MDTypography>
          ) : null}
        </Grid>
        <Grid item xs={12} md={4}>
          <MDInput
            type="text"
            label="Teléfono"
            fullWidth
            value={values.phone}
            onChange={(e) => setFieldValue("phone", e.target.value)}
            error={!!errors.phone}
          />
          {errors?.phone ? (
            <MDTypography variant="caption" color="error">
              {errors.phone}
            </MDTypography>
          ) : null}
        </Grid>
      </Grid>
      <Grid item mt={1} xs={12} md={7}>
        <MDTypography variant="button">Género</MDTypography>
        <RadioGroup
          row
          name="gender-radio-buttons-group"
          value={values.gender}
          onChange={(e) => setFieldValue("gender", e.target.value)}
        >
          <FormControlLabel value="female" control={<Radio />} label="Mujer" />
          <FormControlLabel value="male" control={<Radio />} label="Hombre" />
          <FormControlLabel
            value="other"
            control={<Radio />}
            label="No binario"
          />
        </RadioGroup>
        {errors?.gender ? (
          <MDTypography variant="caption" color="error">
            {errors.gender}
          </MDTypography>
        ) : null}
      </Grid>
      <Grid item mt={1} xs={12} md={7}>
        <MDTypography variant="button">Estado civil</MDTypography>
        <RadioGroup
          row
          name="civil-status-radio-buttons-group"
          value={values.civilStatus}
          onChange={(e) => setFieldValue("civilStatus", e.target.value)}
        >
          <FormControlLabel
            value="single"
            control={<Radio />}
            label="Soltero/a"
          />
          <FormControlLabel
            value="married"
            control={<Radio />}
            label="Casado/a"
          />
          <FormControlLabel
            value="divorced"
            control={<Radio />}
            label="Divorciado/a"
          />
        </RadioGroup>
        {errors?.civilStatus ? (
          <MDTypography variant="caption" color="error">
            {errors.civilStatus}
          </MDTypography>
        ) : null}
      </Grid>

      <Grid item mt={1} xs={12} md={7}>
        <MDTypography variant="button">Redes sociales que utiliza</MDTypography>
      </Grid>
      <Grid item mt={1} xs={12} md={7}>
        <Checkbox
          checked={socialNetworksState.instagram}
          onChange={(e) => onChangeSocialNetworks(e)}
          name="instagram"
        />
        <MDTypography variant="button" pr={2}>
          Instagram
        </MDTypography>
        <Checkbox
          checked={socialNetworksState.facebook}
          onChange={(e) => onChangeSocialNetworks(e)}
          name="facebook"
        />
        <MDTypography variant="button" pr={2}>
          Facebook
        </MDTypography>
        <Checkbox
          checked={socialNetworksState.twitter}
          onChange={(e) => onChangeSocialNetworks(e)}
          name="twitter"
        />
        <MDTypography variant="button" pr={2}>
          Twitter
        </MDTypography>
        <Checkbox
          checked={socialNetworksState.linkedin}
          onChange={(e) => onChangeSocialNetworks(e)}
          name="linkedin"
        />
        <MDTypography variant="button" pr={2}>
          Linkedin
        </MDTypography>
      </Grid>
    </Grid>
  );
}

export default StudentOnboardingStep1;
