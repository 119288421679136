import * as Yup from "yup";

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email inválido")
    .required("Este campo es requerido"),
  password: Yup.string()
    .min(4, "La contraseña debe tener al menos 4 caracteres")
    .max(50, "La contraseña debe tener hasta 50 caracteres")
    .required("Este campo es requerido"),
});

export const CompanySignupSchema = Yup.object().shape({
  name: Yup.string().required("Este campo es requerido"),
  cuit: Yup.string().required("Este campo es requerido"),
  email: Yup.string()
    .email("Email inválido")
    .required("Este campo es requerido"),
  password: Yup.string()
    .min(4, "La contraseña debe tener al menos 4 caracteres")
    .max(50, "La contraseña debe tener hasta 50 caracteres")
    .required("Este campo es requerido"),
});

export const StudentSignupSchema = Yup.object().shape({
  name: Yup.string().required("Este campo es requerido"),
  surname: Yup.string().required("Este campo es requerido"),
  studentId: Yup.number().required("Este campo es requerido"),
  dni: Yup.number().required("Este campo es requerido"),
  email: Yup.string()
    .email("Email inválido")
    .required("Este campo es requerido"),
  password: Yup.string()
    .min(4, "La contraseña debe tener al menos 4 caracteres")
    .max(50, "La contraseña debe tener hasta 50 caracteres")
    .required("Este campo es requerido"),
});
