import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import MDTypography from "../../../components/MDTypography";
import React from "react";
import MDBox from "../../../components/MDBox";
import DefaultProjectCard from "../../../examples/Cards/ProjectCards/DefaultProjectCard";

function JobOffers() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDTypography variant="h4">
              Ofertas de pasantía que se aplican a tu perfil
            </MDTypography>
          </Grid>
          <Grid item container spacing={3} xs={12}>
            <Grid item xs={4}>
              <DefaultProjectCard
                title="Empresa: Grupo Acción consultora"
                description="Área de trabajo: Informática"
                action={{
                  type: "internal",
                  route: "/somewhere",
                  color: "white",
                  label: "Ver oferta",
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <DefaultProjectCard
                title="Empresa: Grupo Acción consultora"
                description="Área de trabajo: Informática"
                action={{
                  type: "internal",
                  route: "/somewhere",
                  color: "white",
                  label: "Ver oferta",
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <DefaultProjectCard
                title="Empresa: Grupo Acción consultora"
                description="Área de trabajo: Informática"
                action={{
                  type: "internal",
                  route: "/somewhere",
                  color: "white",
                  label: "Ver oferta",
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="h4">Otras ofertas</MDTypography>
          </Grid>
          <Grid item container spacing={3} xs={12}>
            <Grid item xs={4}>
              <DefaultProjectCard
                title="Empresa: Grupo Acción consultora"
                description="Área de trabajo: Informática"
                action={{
                  type: "internal",
                  route: "/somewhere",
                  color: "white",
                  label: "Ver oferta",
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <DefaultProjectCard
                title="Empresa: Grupo Acción consultora"
                description="Área de trabajo: Informática"
                action={{
                  type: "internal",
                  route: "/somewhere",
                  color: "white",
                  label: "Ver oferta",
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <DefaultProjectCard
                title="Empresa: Grupo Acción consultora"
                description="Área de trabajo: Informática"
                action={{
                  type: "internal",
                  route: "/somewhere",
                  color: "white",
                  label: "Ver oferta",
                }}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={3} xs={12}>
            <Grid item xs={4}>
              <DefaultProjectCard
                title="Empresa: Grupo Acción consultora"
                description="Área de trabajo: Informática"
                action={{
                  type: "internal",
                  route: "/somewhere",
                  color: "white",
                  label: "Ver oferta",
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <DefaultProjectCard
                title="Empresa: Grupo Acción consultora"
                description="Área de trabajo: Informática"
                action={{
                  type: "internal",
                  route: "/somewhere",
                  color: "white",
                  label: "Ver oferta",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default JobOffers;
