import * as Yup from "yup";

export const addJobOfferSchema = Yup.object().shape({
  studentCondition: Yup.string().required("Este campo es requerido"),
  gender: Yup.string().required("Este campo es requerido"),
  ageRange: Yup.string().required("Este campo es requerido"),
  openPositionFinishDate: Yup.string().required("Este campo es requerido"),
  companyArea: Yup.string().required("Este campo es requerido"),
  address: Yup.string().required("Este campo es requerido"),
  isCompanyNameShared: Yup.boolean().required("Este campo es requerido"),
  moneyAmount: Yup.string().required("Este campo es requerido"),
  otherBenefits: Yup.string(),
  schedule: Yup.string().required("Este campo es requerido"),
});
