import React, { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

// @mui icons

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Overview page components
import Header from "layouts/profile/components/Header";

// Images
import MDInput from "components/MDInput";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";

function Overview() {
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({ companyName: "Weg" });
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <Grid container mt={3}>
          <Grid item container xs={12} justifyContent="flex-end">
            {!isEdit ? (
              <MDButton
                variant="text"
                color={"dark"}
                onClick={() => setIsEdit(true)}
              >
                <Icon>edit</Icon>&nbsp;Editar datos
              </MDButton>
            ) : (
              <MDButton
                variant="gradient"
                color="success"
                onClick={() => setIsEdit(false)}
              >
                Guardar
              </MDButton>
            )}
          </Grid>
          {isEdit ? (
            <>
              <Grid item mt={1} xs={12} md={7}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Nombre Empresa"
                    fullWidth
                    value={formData.companyName}
                    onChange={(e) =>
                      setFormData({ companyName: e.target.value })
                    }
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Cuit"
                    value="30-52551891-0"
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid container mt={1}>
                <Grid item xs={12}>
                  <MDTypography variant="h6" fontWeight="medium" color="dark">
                    Datos fiscales
                  </MDTypography>
                </Grid>
                <Grid item mt={1} xs={12} md={7}>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Tipo de Persona Jurídica"
                      value="S.A."
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Condición Fiscal"
                      value="Monotributista"
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={3}>
                    <MDInput
                      type="text"
                      label="Rubro de la razón social"
                      value="Venta de motores y tableros eléctricos"
                      fullWidth
                    />
                  </MDBox>
                  <input
                    accept="*"
                    style={{ display: "none", marginBottom: "100px" }}
                    id="button-file"
                    multiple
                    type="file"
                  />
                  <label htmlFor="button-file">
                    <MDBox
                      display="flex"
                      alignItems="center"
                      mb={3}
                      sx={{ cursor: "pointer" }}
                    >
                      <Icon fontSize="small">picture_as_pdf</Icon>
                      <MDTypography variant="button" fontWeight="bold">
                        &nbsp;Subir constancia de inscripción de AFIP &nbsp;
                      </MDTypography>
                      <MDTypography variant="caption">
                        constanciaAfip.pdf
                      </MDTypography>
                    </MDBox>
                  </label>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item xs={12}>
                  <MDTypography variant="h6" fontWeight="medium" color="dark">
                    Datos de locación y contacto
                  </MDTypography>
                </Grid>
                <Grid item mt={1} xs={12} md={7}>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Domicilio real de la empresa"
                      value="Santiago Pampiglione 4849"
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Domicilio legal de la empresa"
                      value="Santiago Pampiglione 4849"
                      fullWidth
                    />
                  </MDBox>
                </Grid>
                <Grid item container xs={12} md={7} spacing={3}>
                  <Grid item xs={12} md={4}>
                    <MDInput
                      type="text"
                      label="Localidad"
                      value="San Francisco"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MDInput
                      type="text"
                      label="Provincia"
                      value="Córdoba"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MDBox mb={2}>
                      <MDInput
                        type="number"
                        label="Código Postal"
                        value={2400}
                        fullWidth
                      />
                    </MDBox>
                  </Grid>
                </Grid>
                <Grid item container xs={12} md={7} spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      type="text"
                      label="Teléfono"
                      value="03564-421484"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDBox mb={2}>
                      <MDInput
                        type="text"
                        label="Whatsapp"
                        value="03564-421459"
                        fullWidth
                      />
                    </MDBox>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={7}>
                  <MDBox mb={2}>
                    <MDInput
                      type="email"
                      label="Email de la empresa"
                      value="wegee@weg.net"
                      fullWidth
                    />
                  </MDBox>
                </Grid>
              </Grid>
              <Grid container mt={3} mb={5}>
                <Grid item xs={12}>
                  <MDTypography variant="h6" fontWeight="medium" color="dark">
                    Representante/s de la empresa
                  </MDTypography>
                  <Grid item mt={2} xs={12}>
                    <MDButton variant="gradient" color="dark" size="small">
                      + Agregar representante
                    </MDButton>
                  </Grid>
                  <MDBox
                    component="li"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    bgColor={"grey-100"}
                    borderRadius="lg"
                    p={3}
                    mb={0}
                    mt={2}
                  >
                    <MDBox width="100%" display="flex" flexDirection="column">
                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems={{ xs: "flex-start", sm: "center" }}
                        flexDirection={{ xs: "column", sm: "row" }}
                        mb={1}
                      >
                        <MDTypography
                          variant="button"
                          fontWeight="medium"
                          textTransform="capitalize"
                        >
                          Pepe Argento
                        </MDTypography>
                        {isEdit ? (
                          <MDBox
                            display="flex"
                            alignItems="center"
                            mt={{ xs: 2, sm: 0 }}
                            ml={{ xs: -1.5, sm: 0 }}
                          >
                            <MDBox mr={1}>
                              <MDButton variant="text" color={"dark"}>
                                <Icon>edit</Icon>&nbsp;Editar
                              </MDButton>
                            </MDBox>
                            <MDButton variant="text" color="error">
                              <Icon>delete</Icon>&nbsp;Eliminar
                            </MDButton>
                          </MDBox>
                        ) : null}
                      </MDBox>
                      <MDBox mb={1} lineHeight={0}>
                        <MDTypography variant="caption" color="text">
                          CUIT Nº:&nbsp;&nbsp;&nbsp;
                          <MDTypography
                            variant="caption"
                            fontWeight="medium"
                            textTransform="capitalize"
                          >
                            30-52551891-0
                          </MDTypography>
                        </MDTypography>
                      </MDBox>
                      <MDBox mb={1} lineHeight={0}>
                        <MDTypography variant="caption" color="text">
                          Domicilio:&nbsp;&nbsp;&nbsp;
                          <MDTypography variant="caption" fontWeight="medium">
                            Santiago Pampiglione 4849
                          </MDTypography>
                        </MDTypography>
                      </MDBox>
                      <MDTypography variant="caption" color="text">
                        Ocupación:&nbsp;&nbsp;&nbsp;
                        <MDTypography variant="caption" fontWeight="medium">
                          Apoderado
                        </MDTypography>
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid item mt={1} xs={12} md={7}>
                <MDBox mb={2}>
                  <TextField
                    label="Nombre Empresa"
                    defaultValue={formData.companyName}
                    disabled
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <TextField
                    label="Cuit"
                    defaultValue="30-52551891-0"
                    disabled
                    fullWidth
                  />
                </MDBox>
              </Grid>
              <Grid container mt={1}>
                <Grid item xs={12}>
                  <MDTypography variant="h6" fontWeight="medium" color="dark">
                    Datos fiscales
                  </MDTypography>
                </Grid>
                <Grid item mt={1} xs={12} md={7}>
                  <MDBox mb={2}>
                    <TextField
                      label="Tipo de Persona Jurídica"
                      defaultValue="S.A."
                      disabled
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <TextField
                      label="Condición Fiscal"
                      defaultValue="Monotributista"
                      disabled
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={3}>
                    <TextField
                      label="Rubro de la razón social"
                      defaultValue="Venta de motores y tableros eléctricos"
                      disabled
                      fullWidth
                    />
                  </MDBox>
                  <label htmlFor="button-file">
                    <MDBox
                      display="flex"
                      alignItems="center"
                      mb={3}
                      sx={{ cursor: "pointer" }}
                    >
                      <Icon fontSize="small">picture_as_pdf</Icon>
                      <MDTypography variant="caption">
                        constanciaAfip.pdf
                      </MDTypography>
                    </MDBox>
                  </label>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item xs={12}>
                  <MDTypography variant="h6" fontWeight="medium" color="dark">
                    Datos de locación y contacto
                  </MDTypography>
                </Grid>
                <Grid item mt={1} xs={12} md={7}>
                  <MDBox mb={2}>
                    <TextField
                      label="Domicilio real de la empresa"
                      defaultValue="Santiago Pampiglione 4849"
                      disabled
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <TextField
                      label="Domicilio legal de la empresa"
                      defaultValue="Santiago Pampiglione 4849"
                      disabled
                      fullWidth
                    />
                  </MDBox>
                </Grid>
                <Grid item container xs={12} md={7} spacing={3}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Localidad"
                      defaultValue="San Francisco"
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Provincia"
                      defaultValue="Córdoba"
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MDBox mb={2}>
                      <TextField
                        type="number"
                        label="Código Postal"
                        defaultValue={2400}
                        disabled
                        fullWidth
                      />
                    </MDBox>
                  </Grid>
                </Grid>
                <Grid item container xs={12} md={7} spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Teléfono"
                      defaultValue="03564-421484"
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDBox mb={2}>
                      <TextField
                        label="Whatsapp"
                        defaultValue="03564-421459"
                        disabled
                        fullWidth
                      />
                    </MDBox>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={7}>
                  <MDBox mb={2}>
                    <TextField
                      type="email"
                      label="Email de la empresa"
                      defaultValue="wegee@weg.net"
                      disabled
                      fullWidth
                    />
                  </MDBox>
                </Grid>
              </Grid>
              <Grid container mt={3} mb={5}>
                <Grid item xs={12}>
                  <MDTypography variant="h6" fontWeight="medium" color="dark">
                    Representante/s de la empresa
                  </MDTypography>

                  <MDBox
                    component="li"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    bgColor={"grey-100"}
                    borderRadius="lg"
                    p={3}
                    mb={0}
                    mt={2}
                  >
                    <MDBox width="100%" display="flex" flexDirection="column">
                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems={{ xs: "flex-start", sm: "center" }}
                        flexDirection={{ xs: "column", sm: "row" }}
                        mb={1}
                      >
                        <MDTypography
                          variant="button"
                          fontWeight="medium"
                          textTransform="capitalize"
                        >
                          Pepe Argento
                        </MDTypography>
                        {isEdit ? (
                          <MDBox
                            display="flex"
                            alignItems="center"
                            mt={{ xs: 2, sm: 0 }}
                            ml={{ xs: -1.5, sm: 0 }}
                          >
                            <MDBox mr={1}>
                              <MDButton variant="text" color={"dark"}>
                                <Icon>edit</Icon>&nbsp;Editar
                              </MDButton>
                            </MDBox>
                            <MDButton variant="text" color="error">
                              <Icon>delete</Icon>&nbsp;Eliminar
                            </MDButton>
                          </MDBox>
                        ) : null}
                      </MDBox>
                      <MDBox mb={1} lineHeight={0}>
                        <MDTypography variant="caption" color="text">
                          CUIT Nº:&nbsp;&nbsp;&nbsp;
                          <MDTypography
                            variant="caption"
                            fontWeight="medium"
                            textTransform="capitalize"
                          >
                            30-52551891-0
                          </MDTypography>
                        </MDTypography>
                      </MDBox>
                      <MDBox mb={1} lineHeight={0}>
                        <MDTypography variant="caption" color="text">
                          Domicilio:&nbsp;&nbsp;&nbsp;
                          <MDTypography variant="caption" fontWeight="medium">
                            Santiago Pampiglione 4849
                          </MDTypography>
                        </MDTypography>
                      </MDBox>
                      <MDTypography variant="caption" color="text">
                        Ocupación:&nbsp;&nbsp;&nbsp;
                        <MDTypography variant="caption" fontWeight="medium">
                          Apoderado
                        </MDTypography>
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Header>
    </DashboardLayout>
  );
}

export default Overview;
