import MDBox from "components/MDBox";
import PageLayout from "examples/LayoutContainers/PageLayout";
import logo from "../../sae-logo.jpeg";
import { Card } from "@mui/material";
import CompanyOnboarding from "../../pages/company/onboarding/companyOnboarding";
import StudentOnboarding from "../../pages/student/onboarding/studentOnboarding";

function OnboardingLayout() {
  const onboardingType = () => {
    let path = window.location.pathname.split("/");
    return path[1];
  };

  return (
    <PageLayout background={"white"}>
      <MDBox width="100%" mx="auto">
        <img src={logo} width="350" />
      </MDBox>
      <MDBox width="100%" mx="auto">
        <Card>
          {onboardingType() === "student" ? (
            <StudentOnboarding></StudentOnboarding>
          ) : (
            <CompanyOnboarding></CompanyOnboarding>
          )}
        </Card>
      </MDBox>
    </PageLayout>
  );
}

export default OnboardingLayout;
