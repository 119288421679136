import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

function Input({
  type,
  label,
  fullWidth,
  onChange,
  value,
  error,
  errorText,
  ...props
}) {
  return (
    <MDBox mb={2}>
      <MDInput
        type={type}
        label={label}
        fullWidth={fullWidth}
        onChange={onChange}
        value={value}
        error={error}
        {...props}
      />
      {error ? (
        <MDTypography variant="caption" color="error">
          {errorText}
        </MDTypography>
      ) : null}
    </MDBox>
  );
}

export default Input;
