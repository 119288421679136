import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Card } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
  height: "90%",
  overflowY: "scroll",
  width: "1000px",
};

function CustomModal({ open, onClose, children }) {
  return (
    <Modal open={open} onClose={onClose}>
      <Card sx={style}>{children}</Card>
    </Modal>
  );
}

export default CustomModal;
