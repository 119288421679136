import React from "react";
import MDInput from "components/MDInput";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

function StudentOnboardingStep3({ setFieldValue, errors, values, touched }) {
  return (
    <Grid container mt={3}>
      <Grid mt={3} item xs={12}>
        <MDTypography variant="h6" fontWeight="medium" color="dark">
          Datos académicos de nivel medio
        </MDTypography>
      </Grid>
      <Grid item container mt={1} xs={12} spacing={3}>
        <Grid item xs={12} md={6}>
          <MDInput
            type="text"
            label="Entidad estudiantil de nivel medio"
            fullWidth
            value={values.highSchoolName}
            onChange={(e) => setFieldValue("highSchoolName", e.target.value)}
            error={!!errors.highSchoolName}
          />
          {errors?.highSchoolName ? (
            <MDTypography variant="caption" color="error">
              {errors.highSchoolName}
            </MDTypography>
          ) : null}
        </Grid>
        <Grid item xs={12} md={6}>
          <MDInput
            type="text"
            label="Título de nivel medio"
            fullWidth
            value={values.highSchoolDegree}
            onChange={(e) => setFieldValue("highSchoolDegree", e.target.value)}
            error={!!errors.highSchoolDegree}
          />
          {errors?.highSchoolDegree ? (
            <MDTypography variant="caption" color="error">
              {errors.highSchoolDegree}
            </MDTypography>
          ) : null}
        </Grid>
      </Grid>
      <Grid item container mt={0} xs={12} spacing={3}>
        <Grid item xs={12} md={4}>
          <MDInput
            type="text"
            label="Localidad de la entidad"
            fullWidth
            value={values.highSchoolCity}
            onChange={(e) => setFieldValue("highSchoolCity", e.target.value)}
            error={!!errors.highSchoolCity}
          />
          {errors?.highSchoolCity ? (
            <MDTypography variant="caption" color="error">
              {errors.highSchoolCity}
            </MDTypography>
          ) : null}
        </Grid>
        <Grid item xs={12} md={4}>
          <MDInput
            type="text"
            label="Provincia de la entidad"
            fullWidth
            value={values.highSchoolProvince}
            onChange={(e) =>
              setFieldValue("highSchoolProvince", e.target.value)
            }
            error={!!errors.highSchoolProvince}
          />
          {errors?.highSchoolProvince ? (
            <MDTypography variant="caption" color="error">
              {errors.highSchoolProvince}
            </MDTypography>
          ) : null}
        </Grid>
        <Grid item xs={12} md={4}>
          <MDInput
            type="number"
            label="Código Postal de la entidad"
            fullWidth
            value={values.highSchoolPostalCode}
            onChange={(e) =>
              setFieldValue("highSchoolPostalCode", e.target.value)
            }
            error={!!errors.highSchoolPostalCode}
          />
          {errors?.highSchoolPostalCode ? (
            <MDTypography variant="caption" color="error">
              {errors.highSchoolPostalCode}
            </MDTypography>
          ) : null}
        </Grid>
      </Grid>
      <Grid mt={3} item xs={12}>
        <MDTypography variant="h6" fontWeight="medium" color="dark">
          Datos académicos de nivel superior
        </MDTypography>
      </Grid>
      <Grid item container mt={0} xs={12} spacing={3}>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Carrera de grado en curso
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={values.careerId}
              label="Carrera de grado en curso"
              onChange={(e) => setFieldValue("careerId", e.target.value)}
            >
              <MenuItem value={1}>
                Ingeniería en Sistemas de Información
              </MenuItem>
              <MenuItem value={2}>Ingeniería Química</MenuItem>
              <MenuItem value={3}>Ingeniería Electrónica</MenuItem>
              <MenuItem value={4}>Ingeniería Electromecánica</MenuItem>
              <MenuItem value={5}>Ingeniería Industrial</MenuItem>
              <MenuItem value={6}>
                Licenciatura en Administración Rural
              </MenuItem>
              <MenuItem value={7}>Tecnicatura en Programación</MenuItem>
            </Select>
          </FormControl>

          {errors?.careerId ? (
            <MDTypography variant="caption" color="error">
              {errors.careerId}
            </MDTypography>
          ) : null}
        </Grid>
        <Grid item xs={12} md={3}>
          <MDInput
            type="number"
            label="Cantidad de materias aprobadas"
            fullWidth
            value={values.approvedSubjectAmount}
            onChange={(e) =>
              setFieldValue("approvedSubjectAmount", e.target.value)
            }
            error={!!errors.approvedSubjectAmount}
          />
          {errors?.approvedSubjectAmount ? (
            <MDTypography variant="caption" color="error">
              {errors.approvedSubjectAmount}
            </MDTypography>
          ) : null}
        </Grid>
        <Grid item xs={12} md={3}>
          <MDInput
            type="number"
            label="Año de ingreso"
            fullWidth
            value={values.startYear}
            onChange={(e) => setFieldValue("startYear", e.target.value)}
            error={!!errors.startYear}
          />
          {errors?.startYear ? (
            <MDTypography variant="caption" color="error">
              {errors.startYear}
            </MDTypography>
          ) : null}
        </Grid>
        <Grid item xs={12} md={3}>
          <MDInput
            type="number"
            label="Año de curso actual"
            fullWidth
            value={values.currentCareerYear}
            onChange={(e) => setFieldValue("currentCareerYear", e.target.value)}
            error={!!errors.currentCareerYear}
          />
          {errors?.currentCareerYear ? (
            <MDTypography variant="caption" color="error">
              {errors.currentCareerYear}
            </MDTypography>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default StudentOnboardingStep3;
