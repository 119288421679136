import * as Yup from "yup";

export const CompanyOnboardingStep1Schema = Yup.object().shape({
  legalPersonType: Yup.string().required("Este campo es requerido"),
  taxStatus: Yup.string().required("Este campo es requerido"),
  companyPurpose: Yup.string().required("Este campo es requerido"),
  file: Yup.mixed().required("El archivo es requerido"),
});

export const CompanyOnboardingStep2Schema = Yup.object().shape({
  realAddress: Yup.string().required("Este campo es requerido"),
  legalAddress: Yup.string().required("Este campo es requerido"),
  city: Yup.string().required("Este campo es requerido"),
  province: Yup.string().required("Este campo es requerido"),
  postalCode: Yup.number().required("Este campo es requerido"),
  phone: Yup.string().required("Este campo es requerido"),
  whatsapp: Yup.string().required("Este campo es requerido"),
  website: Yup.string().required("Este campo es requerido"),
  companyEmail: Yup.string()
    .email("Email inválido")
    .required("Este campo es requerido"),
});

export const AddRepresentativesSchema = Yup.object().shape({
  name: Yup.string().required("Este campo es requerido"),
  surname: Yup.string().required("Este campo es requerido"),
  cuit: Yup.string().required("Este campo es requerido"),
  address: Yup.string().required("Este campo es requerido"),
  position: Yup.string().required("Este campo es requerido"),
  positionFile: Yup.mixed().required("El archivo es requerido"),
  dniFile: Yup.mixed().required("El archivo es requerido"),
});

export const StudentOnboardingStep1Schema = Yup.object().shape({
  cuil: Yup.string().required("Este campo es requerido"),
  gender: Yup.string().required("Este campo es requerido"),
  civilStatus: Yup.string().required("Este campo es requerido"),
  birthdate: Yup.string().required("Este campo es requerido"),
  phone: Yup.string().required("Este campo es requerido"),
});

export const StudentOnboardingStep2Schema = Yup.object().shape({
  address: Yup.string().required("Este campo es requerido"),
  city: Yup.string().required("Este campo es requerido"),
  province: Yup.string().required("Este campo es requerido"),
  postalCode: Yup.number().required("Este campo es requerido"),
  isOwner: Yup.boolean().required("Este campo es requerido"),
});

export const StudentOnboardingStep3Schema = Yup.object().shape({
  highSchoolName: Yup.string().required("Este campo es requerido"),
  highSchoolDegree: Yup.string().required("Este campo es requerido"),
  highSchoolCity: Yup.string().required("Este campo es requerido"),
  highSchoolProvince: Yup.string().required("Este campo es requerido"),
  highSchoolPostalCode: Yup.number().required("Este campo es requerido"),
  careerId: Yup.number().required("Este campo es requerido"),
  approvedSubjectAmount: Yup.number().required("Este campo es requerido"),
  startYear: Yup.number().required("Este campo es requerido"),
  currentCareerYear: Yup.number().required("Este campo es requerido"),
});

export const StudentOnboardingStep4Schema = Yup.object().shape({
  hasHealthInsurance: Yup.boolean().required("Este campo es requerido"),
  healthInsurance: Yup.string(),
  bloodType: Yup.string().required("Este campo es requerido"),
  bloodDonor: Yup.boolean().required("Este campo es requerido"),
});

export const StudentOnboardingStep5Schema = Yup.object().shape({
  personalMotivation: Yup.string().required("Este campo es requerido"),
});

export const AddExperienceSchema = Yup.object().shape({
  enterprise: Yup.string().required("Este campo es requerido"),
  position: Yup.string().required("Este campo es requerido"),
  referralName: Yup.string().required("Este campo es requerido"),
  referralPhone: Yup.string().required("Este campo es requerido"),
  startDate: Yup.string().required("Este campo es requerido"),
  endDate: Yup.string().required("Este campo es requerido"),
});

export const AddTaskSchema = Yup.object().shape({
  task: Yup.string().required("Este campo es requerido"),
});

export const AddLanguageSchema = Yup.object().shape({
  language: Yup.string().required("Este campo es requerido"),
  level: Yup.string().required("Este campo es requerido"),
  hasCertificate: Yup.boolean().required("Este campo es requerido"),
});

export const AddCertificationSchema = Yup.object().shape({
  name: Yup.string().required("Este campo es requerido"),
  year: Yup.string().required("Este campo es requerido"),
  hasCertificate: Yup.boolean().required("Este campo es requerido"),
});
