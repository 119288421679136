import React, { useState } from "react";
import { Formik } from "formik";
import Modal from "@mui/material/Modal";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Grid, Card, Box } from "@mui/material";
import MDInput from "components/MDInput";
import TextField from "@mui/material/TextField";
import {
  AddExperienceSchema,
  AddLanguageSchema,
  AddCertificationSchema,
  AddTaskSchema,
} from "helpers/schemas/onboardingSchemas";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import Chip from "@mui/material/Chip";
import CancelIcon from "@mui/icons-material/Cancel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import AddIcon from "@mui/icons-material/Add";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
  minWidth: "400px",
};

const knowledgesList = [
  { title: "Redes sociales" },
  { title: "Ofimática" },
  { title: "Diseño Gráfico" },
  { title: "Mantenimiento de PC" },
  { title: "Bases de datos" },
  { title: "Circuitos electrónicos" },
  { title: "Programación" },
  {
    title: "PHP",
  },
  {
    title: "Java",
  },
  {
    title: "Javascript",
  },
  {
    title: "Desarrollo Frontend",
  },
  {
    title: "Desarrollo Backend",
  },
  {
    title: "Desarrollo Fullstack",
  },
  {
    title: "Desarrollo Mobile",
  },
  {
    title: "C#",
  },
  {
    title: "C",
  },
  {
    title: "Escritura y edición",
  },
  {
    title: "Gestión de campañas de marketing",
  },
  {
    title: "Posicionamiento en buscadores (SEO)",
  },
  {
    title: "Servicios en la nube",
  },
  {
    title: "Aprendizaje automático",
  },
  {
    title: "Mantenimiento de servidores",
  },
  {
    title: "Análisis de negocios",
  },
  {
    title: "Ciencia de la información",
  },
  {
    title: "Investigación de mercado",
  },
  {
    title: "Contabilidad",
  },
  {
    title: "Gestión de la información",
  },
  {
    title: "Seguridad de redes",
  },
  {
    title: "Química orgánica",
  },
  {
    title: "Química inorgánica",
  },
];

const softSkillsList = [
  { title: "Comunicación" },
  { title: "Liderazgo" },
  { title: "Adaptabilidad" },
  { title: "Resolución de problemas" },
  { title: "Trabajo en equipo" },
  { title: "Gestión del tiempo" },
  { title: "Capacidad de respuesta" },
  { title: "Atención a los detalles" },
  { title: "Colaboración" },
  { title: "Creatividad" },
  { title: "Pensamiento crítico" },
  { title: "Inteligencia emocional" },
  { title: "Empatía" },
  { title: "Flexibilidad" },
  { title: "Organización" },
  { title: "Paciencia" },
  { title: "Responsabilidad" },
  { title: "Ética laboral" },
  { title: "Pensamiento estratégico" },
];

function StudentOnboardingStep5({ setFieldValue, errors, values, touched }) {
  const [openModal, setOpenModal] = useState(false);
  const [experiences, setExperience] = useState([]);
  const [isEditModal, setIsEditModal] = useState(false);
  const [currentExperience, setCurrentExperience] = useState({});
  const [task, setCurrentTask] = useState("");
  const [tasks, setTask] = useState([]);
  const [openLanguagesModal, setOpenLanguagesModal] = useState(false);
  const [languages, setLanguage] = useState([]);
  const [isEditLanguageModal, setIsEditLanguageModal] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState({});
  const [openCertificationsModal, setOpenCertificationsModal] = useState(false);
  const [certifications, setCertification] = useState([]);
  const [isEditCertificationModal, setIsEditCertificationModal] =
    useState(false);
  const [currentCertification, setCurrentCertification] = useState({});
  const [currentKnowledge, setCurrentKnowledge] = useState("");
  const [knowledges, setKnowledges] = useState([]);
  const [currentSoftSkill, setCurrentSoftSkill] = useState("");
  const [softSkills, setSoftSkills] = useState([]);

  const handleAddTask = () => {
    setTask([...tasks, task]);
    setCurrentTask("");
  };

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => {
    setOpenModal(false);
    setIsEditModal(false);
  };

  const handleDeleteTask = (name) => {
    let array = [];
    if (!isEditModal) {
      array = tasks.filter((task) => task !== name);
    } else {
      array = currentExperience.tasks.filter((task) => task !== name);
    }
    setTask(array);
  };

  const addExperience = (values) => {
    let object = { ...values, tasks: tasks };
    setExperience([...experiences, object]);
    setTask([]);
    handleCloseModal();
  };

  const onEditExperience = (index) => {
    setIsEditModal(true);
    const currentExperience = {
      ...experiences[index],
      id: index,
    };
    setCurrentExperience(currentExperience);
    setOpenModal(true);
  };

  const updateExperience = (values) => {
    let object = { ...values, tasks: tasks };
    let array = experiences;
    array[currentExperience.id] = object;
    setExperience(array);
    setIsEditModal(false);
    setTask([]);
    handleCloseModal();
  };

  const onDeleteExperience = (index) => {
    const currentExperience = { ...experiences[index], id: index };
    let array = experiences.filter(
      (experience) => experience.name !== currentExperience.name
    );
    setExperience(array);
    setOpenModal(false);
  };

  const handleOpenLanguagesModal = () => setOpenLanguagesModal(true);

  const handleCloseLanguagesModal = () => {
    setOpenLanguagesModal(false);
    setIsEditLanguageModal(false);
  };

  const addLanguage = (values) => {
    setLanguage([...languages, values]);
    handleCloseLanguagesModal();
  };

  const onEditLanguage = (index) => {
    setIsEditLanguageModal(true);
    const currentLanguage = {
      ...languages[index],
      id: index,
    };
    setCurrentLanguage(currentLanguage);
    handleOpenLanguagesModal();
  };

  const updateLanguage = (values) => {
    let array = languages;
    array[currentLanguage.id] = values;
    setLanguage(array);
    setIsEditLanguageModal(false);
    handleCloseLanguagesModal();
  };

  const onDeleteLanguage = (index) => {
    const currentLanguage = { ...languages[index], id: index };
    let array = languages.filter(
      (l) => l.language !== currentLanguage.language
    );
    setLanguage(array);
    handleCloseLanguagesModal(false);
  };

  const handleOpenCertificationsModal = () => setOpenCertificationsModal(true);

  const handleCloseCertificationsModal = () => {
    setOpenCertificationsModal(false);
    setIsEditCertificationModal(false);
  };

  const addCertification = (values) => {
    setCertification([...certifications, values]);
    handleCloseCertificationsModal();
  };

  const onEditCertification = (index) => {
    setIsEditCertificationModal(true);
    const currentCertification = {
      ...certifications[index],
      id: index,
    };
    setCurrentCertification(currentCertification);
    handleOpenCertificationsModal();
  };

  const updateCertification = (values) => {
    let array = certifications;
    array[currentCertification.id] = values;
    setCertification(array);
    setIsEditCertificationModal(false);
    handleCloseCertificationsModal();
  };

  const onDeleteCertification = (index) => {
    const currentCertification = { ...certifications[index], id: index };
    let array = certifications.filter(
      (certification) => certification.name !== currentCertification.name
    );
    setCertification(array);
    handleCloseCertificationsModal(false);
  };

  const handleChangeKnowledge = (value) => {
    if (!value) {
      return;
    }
    setKnowledges([...knowledges, value]);
    setCurrentKnowledge("");
  };

  const handleDeleteKnowledge = (name) => {
    let array = [];
    array = knowledges.filter((knowledge) => knowledge !== name);
    setKnowledges(array);
  };

  const handleChangeSoftSkill = (value) => {
    if (!value) {
      return;
    }
    setSoftSkills([...softSkills, value]);
    setCurrentSoftSkill("");
  };

  const handleDeleteSoftSkill = (name) => {
    let array = [];
    array = softSkills.filter((sk) => sk !== name);
    setSoftSkills(array);
  };

  return (
    <>
      <Grid container mt={3}>
        <Grid item container xs={12} spacing={3} alignItems={"center"}>
          <Grid item>
            <MDTypography variant="h5" fontWeight="medium" color="dark">
              Antecedentes Laborales
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={8}>
            <MDButton
              variant="outlined"
              color="dark"
              size="small"
              onClick={handleOpenModal}
            >
              <AddIcon />
              &nbsp; Agregar
            </MDButton>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          {experiences.length > 0
            ? experiences.map((experience, index) => (
                <MDBox
                  component="li"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  bgColor={"#250b4617"}
                  borderRadius="lg"
                  p={3}
                  mb={0}
                  mt={2}
                  key={index}
                >
                  <Grid item xs={12} md={8}>
                    <MDBox mb={1} lineHeight={0}>
                      <MDTypography variant="caption" fontWeight="medium">
                        Empresa:&nbsp;&nbsp;&nbsp;
                        <MDTypography
                          variant="caption"
                          textTransform="capitalize"
                        >
                          {experience.enterprise}
                        </MDTypography>
                      </MDTypography>
                    </MDBox>
                    <MDBox mb={1} lineHeight={0}>
                      <MDTypography variant="caption" fontWeight="medium">
                        Cargo:&nbsp;&nbsp;&nbsp;
                        <MDTypography variant="caption">
                          {experience.position}
                        </MDTypography>
                      </MDTypography>
                    </MDBox>
                    <MDTypography variant="caption" fontWeight="medium">
                      Período:&nbsp;&nbsp;&nbsp;
                      <MDTypography variant="caption">
                        {experience.startDate} - {experience.endDate}
                      </MDTypography>
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      mt={{ xs: 2, sm: 0 }}
                      ml={{ xs: -1.5, sm: 0 }}
                    >
                      <MDBox mr={1}>
                        <MDButton
                          variant="text"
                          color={"dark"}
                          onClick={() => onEditExperience(index)}
                        >
                          <Icon>edit</Icon>&nbsp;
                        </MDButton>
                      </MDBox>
                      <MDButton
                        variant="text"
                        color="error"
                        onClick={() => onDeleteExperience(index)}
                      >
                        <Icon>delete</Icon>&nbsp;
                      </MDButton>
                    </MDBox>
                  </Grid>
                </MDBox>
              ))
            : null}
        </Grid>
        <Grid item container xs={12} spacing={3} alignItems={"center"} mt={3}>
          <Grid item>
            <MDTypography variant="h5" fontWeight="medium" color="dark">
              Idiomas
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={8}>
            <MDButton
              variant="outlined"
              color="dark"
              size="small"
              onClick={handleOpenLanguagesModal}
            >
              <AddIcon />
              &nbsp; Agregar idioma
            </MDButton>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          {languages.length > 0
            ? languages.map((language, index) => (
                <MDBox
                  component="li"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  bgColor={"#250b4617"}
                  borderRadius="lg"
                  p={3}
                  mb={0}
                  mt={2}
                  key={index}
                >
                  <Grid item xs={12} md={8}>
                    <MDTypography variant="caption" fontWeight="medium">
                      Idioma:&nbsp;&nbsp;&nbsp;
                      <MDTypography variant="caption">
                        {language.language} - {language.level}
                      </MDTypography>
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      mt={{ xs: 2, sm: 0 }}
                      ml={{ xs: -1.5, sm: 0 }}
                    >
                      <MDBox mr={1}>
                        <MDButton
                          variant="text"
                          color={"dark"}
                          onClick={() => onEditLanguage(index)}
                        >
                          <Icon>edit</Icon>&nbsp;
                        </MDButton>
                      </MDBox>
                      <MDButton
                        variant="text"
                        color="error"
                        onClick={() => onDeleteLanguage(index)}
                      >
                        <Icon>delete</Icon>&nbsp;
                      </MDButton>
                    </MDBox>
                  </Grid>
                </MDBox>
              ))
            : null}
        </Grid>
        <Grid item container xs={12} spacing={3} alignItems={"center"} mt={3}>
          <Grid item>
            <MDTypography variant="h5" fontWeight="medium" color="dark">
              Cursos y seminarios
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={8}>
            <MDButton
              variant="outlined"
              color="dark"
              size="small"
              onClick={handleOpenCertificationsModal}
            >
              <AddIcon />
              &nbsp; Agregar curso o seminario
            </MDButton>
          </Grid>
        </Grid>
        <Grid item xs={9}>
          {certifications.length > 0
            ? certifications.map((certification, index) => (
                <MDBox
                  component="li"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  bgColor={"#250b4617"}
                  borderRadius="lg"
                  p={3}
                  mb={0}
                  mt={2}
                  key={index}
                >
                  <Grid item xs={12} md={10}>
                    <MDTypography variant="caption" fontWeight="medium">
                      Curso:&nbsp;&nbsp;&nbsp;
                      <MDTypography variant="caption">
                        {certification.year} - {certification.name}
                      </MDTypography>
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      mt={{ xs: 2, sm: 0 }}
                      ml={{ xs: -1.5, sm: 0 }}
                    >
                      <MDBox mr={1}>
                        <MDButton
                          variant="text"
                          color={"dark"}
                          onClick={() => onEditCertification(index)}
                        >
                          <Icon>edit</Icon>&nbsp;
                        </MDButton>
                      </MDBox>
                      <MDButton
                        variant="text"
                        color="error"
                        onClick={() => onDeleteCertification(index)}
                      >
                        <Icon>delete</Icon>&nbsp;
                      </MDButton>
                    </MDBox>
                  </Grid>
                </MDBox>
              ))
            : null}
        </Grid>
        <Grid item xs={12} mt={3}>
          <MDTypography variant="h5" fontWeight="medium" color="dark">
            Conocimientos
          </MDTypography>
        </Grid>
        <Grid item xs={12} md={3} mt={3}>
          <Autocomplete
            id="knowledges"
            freeSolo
            onChange={(event, newValue) => {
              handleChangeKnowledge(newValue);
            }}
            value={currentKnowledge}
            options={knowledgesList.map((option) => option.title)}
            renderInput={(params) => (
              <TextField {...params} label="Buscar..." />
            )}
          />
        </Grid>
        <Grid item container spacing={1} mt={2}>
          {knowledges.map((knowledge) => {
            return (
              <Grid item>
                <Chip
                  label={knowledge}
                  deleteIcon={<CancelIcon />}
                  onDelete={() => handleDeleteKnowledge(knowledge)}
                  variant="outlined"
                />
              </Grid>
            );
          })}
        </Grid>
        <Grid item xs={12} mt={3}>
          <MDTypography variant="h5" fontWeight="medium" color="dark">
            Habilidades blandas
          </MDTypography>
        </Grid>
        <Grid item xs={12} md={3} mt={3}>
          <Autocomplete
            id="soft-skills"
            freeSolo
            onChange={(event, newValue) => {
              handleChangeSoftSkill(newValue);
            }}
            value={currentSoftSkill}
            options={softSkillsList.map((option) => option.title)}
            renderInput={(params) => (
              <TextField {...params} label="Buscar..." />
            )}
          />
        </Grid>
        <Grid item container spacing={1} mt={2}>
          {softSkills.map((sk) => {
            return (
              <Grid item>
                <Chip
                  label={sk}
                  deleteIcon={<CancelIcon />}
                  onDelete={() => handleDeleteSoftSkill(sk)}
                  variant="outlined"
                />
              </Grid>
            );
          })}
        </Grid>
        <Grid item xs={12} mt={3}>
          <MDTypography variant="h5" fontWeight="medium" color="dark">
            Motivación personal
          </MDTypography>
        </Grid>
        <Grid item mt={3} xs={12}>
          <TextField
            multiline
            fullWidth
            rows={4}
            variant="outlined"
            value={values.personalMotivation}
            onChange={(e) =>
              setFieldValue("personalMotivation", e.target.value)
            }
            error={!!errors.personalMotivation}
          />
          {errors?.personalMotivation ? (
            <MDTypography variant="caption" color="error">
              {errors.personalMotivation}
            </MDTypography>
          ) : null}
        </Grid>
      </Grid>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Formik
          initialValues={{
            id: isEditModal ? currentExperience.id : "",
            enterprise: isEditModal ? currentExperience.enterprise : "",
            position: isEditModal ? currentExperience.position : "",
            referralName: isEditModal ? currentExperience.referralName : "",
            referralPhone: isEditModal ? currentExperience.referralPhone : "",
            startDate: isEditModal ? currentExperience.startDate : "2022-01-01",
            endDate: isEditModal ? currentExperience.endDate : "2022-01-01",
          }}
          validationSchema={AddExperienceSchema}
          validateOnChange={false}
          onSubmit={isEditModal ? updateExperience : addExperience}
        >
          {({ errors, values, setFieldValue, handleSubmit }) => (
            <Card sx={style}>
              <MDTypography variant="h5" fontWeight="medium" color="dark">
                {isEditModal
                  ? "Editar antecedente laboral"
                  : "Agregar antecedente laboral"}
              </MDTypography>
              <Grid container mt={1} spacing={2}>
                <Grid item container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      type="text"
                      label="Empresa"
                      fullWidth
                      value={values.enterprise}
                      onChange={(e) =>
                        setFieldValue("enterprise", e.target.value)
                      }
                      error={!!errors.enterprise}
                    />
                    {errors?.enterprise ? (
                      <MDTypography variant="caption" color="error">
                        {errors.enterprise}
                      </MDTypography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      type="text"
                      label="Cargo"
                      fullWidth
                      value={values.position}
                      onChange={(e) =>
                        setFieldValue("position", e.target.value)
                      }
                      error={!!errors.position}
                    />
                    {errors?.position ? (
                      <MDTypography variant="caption" color="error">
                        {errors.position}
                      </MDTypography>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      type="text"
                      label="Referente"
                      fullWidth
                      value={values.referralName}
                      onChange={(e) =>
                        setFieldValue("referralName", e.target.value)
                      }
                      error={!!errors.referralName}
                    />
                    {errors?.referralName ? (
                      <MDTypography variant="caption" color="error">
                        {errors.referralName}
                      </MDTypography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      type="text"
                      label="Teléfono referente"
                      fullWidth
                      value={values.referralPhone}
                      onChange={(e) =>
                        setFieldValue("referralPhone", e.target.value)
                      }
                      error={!!errors.referralPhone}
                    />
                    {errors?.referralPhone ? (
                      <MDTypography variant="caption" color="error">
                        {errors.referralPhone}
                      </MDTypography>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MDInput
                    type="date"
                    label="Fecha comienzo"
                    fullWidth
                    value={values.startDate}
                    onChange={(e) => setFieldValue("startDate", e.target.value)}
                    error={!!errors.startDate}
                  />
                  {errors?.startDate ? (
                    <MDTypography variant="caption" color="error">
                      {errors.startDate}
                    </MDTypography>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={6}>
                  <MDInput
                    type="date"
                    label="Fecha de finalización"
                    fullWidth
                    value={values.endDate}
                    onChange={(e) => setFieldValue("endDate", e.target.value)}
                    error={!!errors.endDate}
                  />
                  {errors?.endDate ? (
                    <MDTypography variant="caption" color="error">
                      {errors.endDate}
                    </MDTypography>
                  ) : null}
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={12} sm={2}>
                    <MDTypography variant="h6" fontWeight="medium" color="dark">
                      Tareas
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDTypography variant="caption" color="info">
                      Para agregar una nueva tarea escriba en el campo inferior
                      y luego haga click en "+"
                    </MDTypography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} spacing={3}>
                  <Grid item xs={8}>
                    <MDInput
                      type="text"
                      label="Tarea"
                      value={task}
                      fullWidth
                      onChange={(e) => setCurrentTask(e.target.value)}
                    />
                    {/* {errors?.task ? (
                      <MDTypography variant="caption" color="error">
                        {errors.task}
                      </MDTypography>
                    ) : null} */}
                  </Grid>
                  <Grid item xs={2}>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      size="small"
                      iconOnly
                      onClick={() => handleAddTask()}
                    >
                      <AddIcon />
                    </MDButton>
                  </Grid>
                </Grid>
                <Grid item container spacing={1}>
                  {isEditModal
                    ? currentExperience.tasks.map((task) => {
                        return (
                          <Grid item xs={12}>
                            <Chip
                              label={task}
                              onDelete={() => handleDeleteTask(task)}
                              deleteIcon={<CancelIcon />}
                              variant="outlined"
                            />
                          </Grid>
                        );
                      })
                    : tasks.map((task) => {
                        return (
                          <Grid item xs={12}>
                            <Chip
                              label={task}
                              onDelete={() => handleDeleteTask(task)}
                              deleteIcon={<CancelIcon />}
                              variant="outlined"
                            />
                          </Grid>
                        );
                      })}
                </Grid>

                <Grid item container justifyContent="space-between" xs={12}>
                  <Grid item>
                    <MDButton
                      variant="text"
                      color="info"
                      size="small"
                      onClick={handleCloseModal}
                    >
                      Cancelar
                    </MDButton>
                  </Grid>
                  <Grid item>
                    <MDButton
                      color="info"
                      size="small"
                      onClick={() => handleSubmit()}
                    >
                      {isEditModal ? "Aceptar" : "Agregar"}
                    </MDButton>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          )}
        </Formik>
      </Modal>
      <Modal
        open={openLanguagesModal}
        onClose={handleCloseLanguagesModal}
        aria-labelledby="modal-languages"
        aria-describedby="modal-add-languages"
      >
        <Formik
          initialValues={{
            language: isEditLanguageModal ? currentLanguage.language : "",
            level: isEditLanguageModal ? currentLanguage.level : "",
            hasCertificate: isEditLanguageModal
              ? currentLanguage.hasCertificate
              : "",
          }}
          validationSchema={AddLanguageSchema}
          validateOnChange={false}
          onSubmit={isEditLanguageModal ? updateLanguage : addLanguage}
        >
          {({ errors, values, setFieldValue, handleSubmit }) => (
            <Card sx={style}>
              <MDTypography variant="h5" fontWeight="medium" color="dark">
                {isEditLanguageModal ? "Editar idioma" : "Agregar nuevo idioma"}
              </MDTypography>
              <Grid container mt={1} mb={2} spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Idioma
                    </InputLabel>
                    <Select
                      id="language-select"
                      value={values.language}
                      label="Carrera de grado en curso"
                      onChange={(e) =>
                        setFieldValue("language", e.target.value)
                      }
                    >
                      <MenuItem value={"Inglés"}>Inglés</MenuItem>
                      <MenuItem value={"Italiano"}>Italiano</MenuItem>
                      <MenuItem value={"Francés"}>Francés</MenuItem>
                      <MenuItem value={"Alemán"}>Alemán</MenuItem>
                      <MenuItem value={"Alemán"}>Portugués</MenuItem>
                    </Select>
                  </FormControl>

                  {errors?.language ? (
                    <MDTypography variant="caption" color="error">
                      {errors.language}
                    </MDTypography>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Nivel</InputLabel>
                    <Select
                      id="level-select"
                      value={values.level}
                      label="Nivel"
                      onChange={(e) => setFieldValue("level", e.target.value)}
                    >
                      <MenuItem value={"Básico"}>Básico</MenuItem>
                      <MenuItem value={"Intermedio"}>Intermedio</MenuItem>
                      <MenuItem value={"Avanzado"}>Avanzado</MenuItem>
                    </Select>
                  </FormControl>

                  {errors?.language ? (
                    <MDTypography variant="caption" color="error">
                      {errors.language}
                    </MDTypography>
                  ) : null}
                </Grid>
              </Grid>
              <MDTypography variant="button">Tiene certificado</MDTypography>
              <RadioGroup
                row
                name="health-insurance-radio-buttons-group"
                value={values.hasCertificate}
                onChange={(e) =>
                  setFieldValue("hasCertificate", e.target.value)
                }
              >
                <FormControlLabel value={true} control={<Radio />} label="Si" />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
              {errors?.hasCertificate ? (
                <MDTypography variant="caption" color="error">
                  {errors.hasCertificate}
                </MDTypography>
              ) : null}

              <Grid item container justifyContent="space-between" xs={12}>
                <Grid item>
                  <MDButton
                    variant="text"
                    color="info"
                    size="small"
                    onClick={handleCloseLanguagesModal}
                  >
                    Cancelar
                  </MDButton>
                </Grid>
                <Grid item>
                  <MDButton
                    color="info"
                    size="small"
                    onClick={() => handleSubmit()}
                  >
                    {isEditLanguageModal ? "Aceptar" : "Agregar"}
                  </MDButton>
                </Grid>
              </Grid>
            </Card>
          )}
        </Formik>
      </Modal>
      <Modal
        open={openCertificationsModal}
        onClose={handleCloseCertificationsModal}
      >
        <Formik
          initialValues={{
            name: isEditCertificationModal ? currentCertification.name : "",
            year: isEditCertificationModal
              ? currentCertification.year
              : "2022-01-01",
            hasCertificate: isEditCertificationModal
              ? currentCertification.hasCertificate
              : "",
          }}
          validationSchema={AddCertificationSchema}
          validateOnChange={false}
          onSubmit={
            isEditCertificationModal ? updateCertification : addCertification
          }
        >
          {({ errors, values, setFieldValue, handleSubmit }) => (
            <Card sx={style}>
              <MDTypography variant="h5" fontWeight="medium" color="dark">
                {isEditCertificationModal
                  ? "Editar  curso o seminario"
                  : "Agregar nuevo curso o seminario"}
              </MDTypography>
              <Grid container mt={1} mb={2} spacing={2}>
                <Grid item xs={12}>
                  <MDInput
                    type="date"
                    label="Fecha"
                    fullWidth
                    value={values.year}
                    onChange={(e) => setFieldValue("year", e.target.value)}
                    error={!!errors.year}
                  />
                  {errors?.year ? (
                    <MDTypography variant="caption" color="error">
                      {errors.year}
                    </MDTypography>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <MDInput
                    type="text"
                    label="Nombre"
                    fullWidth
                    value={values.name}
                    onChange={(e) => setFieldValue("name", e.target.value)}
                    error={!!errors.name}
                  />
                  {errors?.name ? (
                    <MDTypography variant="caption" color="error">
                      {errors.name}
                    </MDTypography>
                  ) : null}
                </Grid>
              </Grid>
              <MDTypography variant="button">Tiene certificado</MDTypography>
              <RadioGroup
                row
                name="health-insurance-radio-buttons-group"
                value={values.hasCertificate}
                onChange={(e) =>
                  setFieldValue("hasCertificate", e.target.value)
                }
              >
                <FormControlLabel value={true} control={<Radio />} label="Si" />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
              {errors?.hasCertificate ? (
                <MDTypography variant="caption" color="error">
                  {errors.hasCertificate}
                </MDTypography>
              ) : null}

              <Grid item container justifyContent="space-between" xs={12}>
                <Grid item>
                  <MDButton
                    variant="text"
                    color="info"
                    size="small"
                    onClick={handleCloseCertificationsModal}
                  >
                    Cancelar
                  </MDButton>
                </Grid>
                <Grid item>
                  <MDButton
                    color="info"
                    size="small"
                    onClick={() => handleSubmit()}
                  >
                    {isEditModal ? "Aceptar" : "Agregar"}
                  </MDButton>
                </Grid>
              </Grid>
            </Card>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default StudentOnboardingStep5;
