import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CustomModal from "components/molecules/modal/modal";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import AddIcon from "@mui/icons-material/Add";
import { addJobOfferSchema } from "helpers/schemas/companySchemas";
import { Formik } from "formik";
import Input from "components/molecules/input/input";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import CancelIcon from "@mui/icons-material/Cancel";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "components/MDInput";
import MDBadge from "components/MDBadge";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MDSnackbar from "../../../components/MDSnackbar";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const companyAreas = [
  { title: "Técnica" },
  { title: "Administrativa" },
  { title: "Técnica" },
  { title: "Investigación y Desarrollo" },
  { title: "Producción, Sector Corte y Soldadura" },
  { title: "Sistemas de Información" },
  { title: "Calidad" },
  { title: "Sistemas de Calidad" },
  { title: "Producción, Sector Montaje" },
  { title: "Ingeniería de Procesos, Sector Mantenimiento" },
  { title: "Acopio de Cereales" },
  { title: "Organización" },
  { title: "Posventa" },
  { title: "Informática de Telemarketing" },
  { title: "Laboratorio" },
  { title: "Organización de Procesos" },
  { title: "Procesos" },
  { title: "Diseño" },
  { title: "Sistemas" },
  { title: "Ingeniería y Montaje" },
  { title: "Oficina Técnica" },
  { title: "Higiene y Seguridad" },
  { title: "Control de Producción" },
];
const knowledgesList = [
  { title: "Redes sociales" },
  { title: "Ofimática" },
  { title: "Diseño Gráfico" },
  { title: "Mantenimiento de PC" },
  { title: "Bases de datos" },
  { title: "Circuitos electrónicos" },
  { title: "Programación" },
  {
    title: "PHP",
  },
  {
    title: "Java",
  },
  {
    title: "Javascript",
  },
  {
    title: "Desarrollo Frontend",
  },
  {
    title: "Desarrollo Backend",
  },
  {
    title: "Desarrollo Fullstack",
  },
  {
    title: "Desarrollo Mobile",
  },
  {
    title: "C#",
  },
  {
    title: "C",
  },
  {
    title: "Escritura y edición",
  },
  {
    title: "Gestión de campañas de marketing",
  },
  {
    title: "Posicionamiento en buscadores (SEO)",
  },
  {
    title: "Servicios en la nube",
  },
  {
    title: "Aprendizaje automático",
  },
  {
    title: "Mantenimiento de servidores",
  },
  {
    title: "Análisis de negocios",
  },
  {
    title: "Ciencia de la información",
  },
  {
    title: "Investigación de mercado",
  },
  {
    title: "Contabilidad",
  },
  {
    title: "Gestión de la información",
  },
  {
    title: "Seguridad de redes",
  },
  {
    title: "Química orgánica",
  },
  {
    title: "Química inorgánica",
  },
  { title: "Gestión de calidad" },
  { title: "ISO 9000" },
  { title: "Excel" },
  { title: "Paquete de Office" },
  { title: "Sistema de planificación de recursos empresariales (ERP)" },
];

const columns = [
  { Header: "Área", accessor: "function", align: "left" },
  { Header: "Estado", accessor: "status", align: "center" },
  {
    Header: "Fecha de finalización de búsqueda",
    accessor: "employed",
    align: "center",
  },
  { Header: "Acciones", accessor: "action", align: "center" },
];

const careers = [
  "Ingeniería en Sistemas de Información",
  "Ingeniería Química",
  "Ingeniería Electrónica",
  "Ingeniería Electromecánica",
  "Licenciatura en Administración Rural",
  "Tecnicatura en Programación",
];

function JobOffers() {
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [jobOffers, setJobOffers] = useState([]);
  const [isEditModal, setIsEditModal] = useState(false);
  const [currentJobOffer, setCurrentJobOffer] = useState({});
  const [currentKnowledge, setCurrentKnowledge] = useState("");
  const [knowledges, setKnowledges] = useState([]);
  const [task, setCurrentTask] = useState("");
  const [tasks, setTask] = useState([]);
  const [careersName, setCareers] = useState([]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const addJobOffer = (values) => {
    setJobOffers([...jobOffers, values]);
    handleCloseModal();
    setOpenNotification(true);
  };

  const onEditJobOffer = () => {
    setIsEditModal(true);
    const currentJobOffer = {
      ...jobOffers[0],
      id: 0,
    };
    setCurrentJobOffer(currentJobOffer);
    setOpenModal(true);
  };

  const updateJobOffer = (values) => {
    setIsEditModal(false);
    setOpenModal(false);
  };

  const handleAddTask = () => {
    setTask([...tasks, task]);
    setCurrentTask("");
  };

  const handleDeleteTask = (name) => {
    let array = [];
    if (!isEditModal) {
      array = tasks.filter((task) => task !== name);
    } else {
      array = tasks.filter((task) => task !== name);
    }
    setTask(array);
  };

  const handleChangeKnowledge = (value) => {
    if (!value) {
      return;
    }
    setKnowledges([...knowledges, value]);
    setCurrentKnowledge("");
  };

  const handleDeleteKnowledge = (name) => {
    let array = [];
    array = knowledges.filter((knowledge) => knowledge !== name);
    setKnowledges(array);
  };

  const initialValuesDecider = () => {
    if (isEditModal) {
      return {
        studentCondition: currentJobOffer.studentCondition,
        gender: currentJobOffer.gender,
        ageRange: currentJobOffer.ageRange,
        openPositionFinishDate: currentJobOffer.openPositionFinishDate,
        companyArea: currentJobOffer.companyArea,
        address: currentJobOffer.address,
        isCompanyNameShared: currentJobOffer.isCompanyNameShared,
        moneyAmount: currentJobOffer.moneyAmount,
        otherBenefits: currentJobOffer.otherBenefits,
        schedule: currentJobOffer.schedule,
      };
    } else {
      return {
        studentCondition: "",
        gender: "",
        ageRange: "",
        openPositionFinishDate: "2022-11-11",
        companyArea: "",
        address: "",
        isCompanyNameShared: "",
        moneyAmount: "",
        otherBenefits: "",
        schedule: "",
      };
    }
  };

  const rows = [
    {
      function: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {jobOffers[0]?.companyArea}
        </MDTypography>
      ),
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent="En validación"
            color="success"
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      employed: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {jobOffers[0]?.openPositionFinishDate}
        </MDTypography>
      ),
      action: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
          onClick={() => onEditJobOffer()}
        >
          Editar
        </MDTypography>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDSnackbar
        icon="notifications"
        title="¡Oferta de trabajo creada!"
        content="Presiona editar para poder ver la especialidad que es apta para esta oferta de trabajo."
        open={openNotification}
        close={() => setOpenNotification(false)}
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDTypography variant="h3">Ofertas de pasantía</MDTypography>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="contained"
                bgColor="dark"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container>
                  <Grid item>
                    <MDButton
                      color="light"
                      variant="outlined"
                      fullWidth
                      onClick={() => setOpenModal(true)}
                    >
                      <AddIcon />
                      &nbsp; Agregar nueva solicitud
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3} pb={3}>
                {jobOffers.length > 0 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                ) : (
                  <MDTypography variant="caption" ml={3}>
                    Aún no hay ofertas de pasantía creadas
                  </MDTypography>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <CustomModal open={openModal} onClose={handleCloseModal}>
        <Formik
          initialValues={initialValuesDecider()}
          validationSchema={addJobOfferSchema}
          validateOnChange={true}
          onSubmit={isEditModal ? updateJobOffer : addJobOffer}
        >
          {({ errors, values, setFieldValue, handleSubmit, touched }) => (
            <>
              <MDTypography variant="h5" fontWeight="medium" color="dark">
                {isEditModal
                  ? "Editar oferta"
                  : "Agregar nueva solicitud de oferta de pasantía"}
              </MDTypography>
              <Grid container mt={2} mb={2}>
                <Grid item xs={12} mb={2}>
                  <MDTypography variant="h6" color="dark">
                    Acerca del estudiante
                  </MDTypography>
                </Grid>
                {isEditModal && (
                  <Grid item xs={12} mb={3}>
                    <Card>
                      <MDBox
                        display="grid"
                        alignItems="center"
                        bgColor="warning"
                        color="white"
                        shadow="md"
                        borderRadius="lg"
                        variant="gradient"
                        p={2}
                      >
                        <MDTypography
                          variant="h6"
                          color="white"
                          fontWeight="normal"
                        >
                          <b>Especialidad sugerida para esta oferta:</b>
                        </MDTypography>
                        <Grid container spacing={1}>
                          <Grid item>
                            <MDBadge
                              badgeContent="Ingeniería en Sistemas de Información"
                              color="light"
                              variant="contained"
                              size="md"
                            />
                          </Grid>
                        </Grid>
                      </MDBox>
                    </Card>
                  </Grid>
                )}

                <Grid item xs={12} mb={2}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-multiple-career-label">
                      Carreras
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-career-label"
                      id="multiple-select"
                      multiple
                      value={careersName}
                      onChange={(e) => setCareers(e.target.value)}
                      input={<OutlinedInput label="Name" />}
                      MenuProps={MenuProps}
                    >
                      {careers.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          style={getStyles(name, careersName, theme)}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    type={"text"}
                    label={"Condición del estudiante"}
                    fullWidth={true}
                    onChange={(e) =>
                      setFieldValue("studentCondition", e.target.value)
                    }
                    value={values.studentCondition}
                    error={
                      !!errors.studentCondition && touched.studentCondition
                    }
                    errorText={errors.studentCondition}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    type={"text"}
                    label={"Rango etario"}
                    fullWidth={true}
                    onChange={(e) => setFieldValue("ageRange", e.target.value)}
                    value={values.ageRange}
                    error={!!errors.ageRange && touched.ageRange}
                    errorText={errors.ageRange}
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <MDTypography variant="button">Género</MDTypography>
                  <RadioGroup
                    row
                    name="gender-radio-buttons-group"
                    value={values.gender}
                    onChange={(e) => setFieldValue("gender", e.target.value)}
                  >
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Mujer"
                    />
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Hombre"
                    />
                    <FormControlLabel
                      value="x"
                      control={<Radio />}
                      label="No binario"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label="Indistinto"
                    />
                  </RadioGroup>
                  {errors?.gender && touched.gender ? (
                    <MDTypography variant="caption" color="error">
                      {errors.gender}
                    </MDTypography>
                  ) : null}
                </Grid>
                <Grid item xs={12} mt={3} mb={1}>
                  <MDTypography variant="h6" color="dark">
                    Acerca de la pasantía
                  </MDTypography>
                </Grid>
                <Grid item xs={12}>
                  <MDTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                  >
                    Tareas a realizar
                  </MDTypography>
                </Grid>
                <Grid item xs={12} mb={1}>
                  <MDTypography variant="caption" color="info">
                    Para agregar una nueva tarea escriba en el campo inferior y
                    luego haga click en "+"
                  </MDTypography>
                </Grid>
                <Grid item container xs={12} spacing={3}>
                  <Grid item xs={8}>
                    <MDInput
                      type="text"
                      label="Tarea"
                      value={task}
                      fullWidth
                      onChange={(e) => setCurrentTask(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      size="small"
                      iconOnly
                      onClick={() => handleAddTask()}
                    >
                      <AddIcon />
                    </MDButton>
                  </Grid>
                </Grid>
                <Grid item container spacing={1} mt={1}>
                  {isEditModal
                    ? tasks.map((task) => {
                        return (
                          <Grid item xs={12}>
                            <Chip
                              label={task}
                              key={task}
                              onDelete={() => handleDeleteTask(task)}
                              deleteIcon={<CancelIcon />}
                              variant="outlined"
                            />
                          </Grid>
                        );
                      })
                    : tasks.map((task) => {
                        return (
                          <Grid item xs={12}>
                            <Chip
                              label={task}
                              onDelete={() => handleDeleteTask(task)}
                              deleteIcon={<CancelIcon />}
                              variant="outlined"
                            />
                          </Grid>
                        );
                      })}
                </Grid>
                <Grid item xs={12} mt={2} mb={1}>
                  <MDTypography
                    variant="button"
                    fontWeight="medium"
                    color="dark"
                  >
                    Conocimientos
                  </MDTypography>
                </Grid>
                <Grid item xs={12} mb={2} mt={0}>
                  <Autocomplete
                    id="knowledges"
                    freeSolo
                    onChange={(event, newValue) => {
                      handleChangeKnowledge(newValue);
                    }}
                    value={currentKnowledge}
                    options={knowledgesList.map((option) => option.title)}
                    renderInput={(params) => (
                      <TextField {...params} label="Buscar..." />
                    )}
                  />
                </Grid>
                <Grid item container spacing={1} mb={3}>
                  {knowledges.map((knowledge) => {
                    return (
                      <Grid item>
                        <Chip
                          label={knowledge}
                          deleteIcon={<CancelIcon />}
                          onDelete={() => handleDeleteKnowledge(knowledge)}
                          variant="outlined"
                        />
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid item xs={12} mb={2} mt={0}>
                  <Autocomplete
                    id="companyArea"
                    freeSolo
                    onChange={(event, newValue) => {
                      setFieldValue("companyArea", newValue);
                    }}
                    value={values.companyArea}
                    options={companyAreas.map((option) => option.title)}
                    renderInput={(params) => (
                      <TextField {...params} label="Área de la empresa" />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    type={"text"}
                    label={"Dirección donde se realizarán las tareas"}
                    fullWidth={true}
                    onChange={(e) => setFieldValue("address", e.target.value)}
                    value={values.address}
                    error={!!errors.address && touched.address}
                    errorText={errors.address}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    type={"text"}
                    label={"Días y horarios de la pasantía"}
                    fullWidth={true}
                    onChange={(e) => setFieldValue("schedule", e.target.value)}
                    value={values.schedule}
                    error={!!errors.schedule && touched.schedule}
                    errorText={errors.schedule}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    type={"text"}
                    label={"Remuneración aproximada"}
                    fullWidth={true}
                    onChange={(e) =>
                      setFieldValue("moneyAmount", e.target.value)
                    }
                    value={values.moneyAmount}
                    error={!!errors.moneyAmount && touched.moneyAmount}
                    errorText={errors.moneyAmount}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    type={"date"}
                    label={"Fecha de cierre de búsqueda"}
                    fullWidth={true}
                    onChange={(e) =>
                      setFieldValue("openPositionFinishDate", e.target.value)
                    }
                    value={values.openPositionFinishDate}
                    error={
                      !!errors.openPositionFinishDate &&
                      touched.openPositionFinishDate
                    }
                    errorText={errors.openPositionFinishDate}
                  />
                </Grid>

                <Grid item xs={12}>
                  <MDTypography variant="button">
                    Permite publicar su nombre en las oportunidades
                  </MDTypography>
                  <RadioGroup
                    row
                    name="gender-radio-buttons-group"
                    value={values.isCompanyNameShared}
                    onChange={(e) =>
                      setFieldValue("isCompanyNameShared", e.target.value)
                    }
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Si"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                  {errors?.isCompanyNameShared &&
                  touched.isCompanyNameShared ? (
                    <MDTypography variant="caption" color="error">
                      {errors.isCompanyNameShared}
                    </MDTypography>
                  ) : null}
                </Grid>
                <Grid item mt={3} mb={2} xs={12}>
                  <TextField
                    label={"Otros beneficios"}
                    multiline
                    fullWidth
                    rows={4}
                    variant="outlined"
                    value={values.otherBenefits}
                    onChange={(e) =>
                      setFieldValue("otherBenefits", e.target.value)
                    }
                    error={!!errors.otherBenefits && touched.otherBenefits}
                  />
                  {errors?.otherBenefits && touched.otherBenefits ? (
                    <MDTypography variant="caption" color="error">
                      {errors.otherBenefits}
                    </MDTypography>
                  ) : null}
                </Grid>

                <Grid item container justifyContent="space-between" xs={12}>
                  <Grid item>
                    <MDButton
                      variant="text"
                      color="info"
                      size="small"
                      onClick={handleCloseModal}
                    >
                      Cancelar
                    </MDButton>
                  </Grid>
                  <Grid item>
                    <MDButton
                      color="info"
                      size="small"
                      onClick={() => handleSubmit()}
                    >
                      {isEditModal ? "Aceptar" : "Agregar"}
                    </MDButton>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </CustomModal>
    </DashboardLayout>
  );
}

export default JobOffers;
