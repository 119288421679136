import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import PageLayout from "examples/LayoutContainers/PageLayout";
import logo from "../../../../sae-logo.jpeg";
import internshipImg from "../../../../4617305.jpg";
import { isMobile } from "../../../../utils/isMobile";

function BasicLayout({ children }) {
  return (
    <PageLayout background={"white"}>
      <MDBox width="100%" mx="auto">
        <img src={logo} width={"350"} />
      </MDBox>
      <Grid container mt={1}>
        <Grid item xs={12} md={6}>
          <img src={internshipImg} style={{ width: "100%" }} />
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={6}
          justifyContent="center"
          alignItems="center"
        >
          {children}
        </Grid>
      </Grid>
    </PageLayout>
  );
}

BasicLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
