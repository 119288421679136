/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import BasicLayout from "layouts/authentication/components/BasicLayout";

function Cover() {
  return (
    <BasicLayout>
      <MDBox
        bgColor="white"
        borderRadius="lg"
        mx={2}
        mt={-3}
        pr={15}
        pl={0}
        textAlign="center"
      >
        <MDTypography variant="h4" fontWeight="medium" color="dark" mt={1}>
          Recuperar contraseña
        </MDTypography>
        <MDTypography variant="h6" color="dark" mt={1}>
          Recibirás un mail para poder recuperar tu contraseña
        </MDTypography>
      </MDBox>
      <MDBox pt={4} pb={3} pr={15} pl={0}>
        <MDBox component="form" role="form">
          <MDBox mb={2}>
            <MDInput type="email" label="Email" fullWidth />
          </MDBox>
          <MDBox mt={4} mb={1}>
            <MDButton variant="gradient" color="info" fullWidth>
              Recuperar contraseña
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </BasicLayout>
  );
}

export default Cover;
