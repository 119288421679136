import { useEffect } from "react";
import { Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import Auth from "../../../services/api/auth";
import { useMaterialUIController, setRole } from "../../../context";
import { LoginSchema } from "helpers/schemas/authSchemas";
import Input from "components/molecules/input/input";
import useAPIError from "../../../hooks";
import Grid from "@mui/material/Grid";

function Basic() {
  const [controller, dispatch] = useMaterialUIController();
  const navigate = useNavigate();
  const { addError } = useAPIError();

  useEffect(() => {
    Auth.logOut();
  }, []);

  const onSubmit = async (values) => {
    const response = await Auth.logIn(values.email, values.password);
    if (response.status === 201) {
      const { role, onboardingComplete } = response.data;
      if (onboardingComplete || role === "admin") {
        navigate("/" + role + "/dashboard");
      } else {
        navigate("/" + role + "/onboarding");
      }
    } else {
      addError(`${response.data.message}`, response.status);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Grid item xs={10}>
        <MDBox textAlign="center">
          <MDTypography variant="h4" fontWeight="medium" color="dark" mt={1}>
            Iniciar sesión
          </MDTypography>
        </MDBox>
      </Grid>
      <Grid item xs={10} sm={7}>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={LoginSchema}
          validateOnChange={true}
          onSubmit={onSubmit}
        >
          {({ errors, setFieldValue, handleSubmit, touched }) => (
            <MDBox pt={2} pb={3}>
              <Input
                type={"email"}
                label={"Email"}
                fullWidth={true}
                onChange={(e) => setFieldValue("email", e.target.value)}
                error={!!errors.email && touched.email}
                errorText={errors.email}
              />
              <Input
                type={"password"}
                label={"Contraseña"}
                fullWidth={true}
                onChange={(e) => setFieldValue("password", e.target.value)}
                error={!!errors.password && touched.password}
                errorText={errors.password}
              />
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Iniciar sesión
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  No tienes una cuenta?{" "}
                  <MDTypography
                    component={Link}
                    to="/auth/signup"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Regístrate
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox mt={1} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  <MDTypography
                    component={Link}
                    to="/auth/password/reset"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Olvidaste tu contraseña?
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          )}
        </Formik>
      </Grid>
    </BasicLayout>
  );
}

export default Basic;
