import Api from "./api";
import authStorage from "../localStorage/authStorage";
class Auth {
  async logIn(email, password) {
    const body = { email, password };

    let response;
    try {
      response = await Api.post("auth/login", body);
    } catch (err) {
      response = err;
    }

    if (response) {
      const { access_token, role } = response.data;
      authStorage.setSession(access_token, role);
    }
    return response;
  }

  async logOut() {
    authStorage.deleteSession();
  }

  async companySignUp(body) {
    let response;
    try {
      response = await Api.post("company", body);
    } catch (err) {
      response = err;
    }

    if (response.status === 201) {
      const res = await this.logIn(body.email, body.password);
      return res;
    }
  }

  async studentSignUp(body) {
    let response;
    try {
      response = await Api.post("student", body);
    } catch (err) {
      response = err;
    }

    return response;
  }
}

export default new Auth();
