/** 
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Custom routes
import authRoutes from "./auth";
import companyRoutes from "./company";
import studentRoutes from "./student";
import adminRoutes from "./admin";

const routes = [
  ...companyRoutes.map((route) => {
    route.route = "/company" + route.route;
    route.role = "company";
    return route;
  }),
  ...studentRoutes.map((route) => {
    route.route = "/student" + route.route;
    route.role = "student";
    return route;
  }),
  ...adminRoutes.map((route) => {
    route.route = "/admin" + route.route;
    route.role = "admin";
    return route;
  }),
  ...authRoutes.map((route) => {
    route.route = "/auth" + route.route;
    return route;
  }),
];

export default routes;
