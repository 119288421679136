import Icon from "@mui/material/Icon";
import SignIn from "../pages/auth/sign-in/index";
import SignUp from "../pages/auth/sign-up/index";
import CompanySignUp from "../pages/auth/sign-up/companySignUp";
import StudentSignUp from "../pages/auth/sign-up/studentSignUp";
import ResetPassword from "../pages/auth/reset-password";

const authRoutes = [
  {
    type: "collapse",
    name: "Cerrar sesión",
    key: "log-out",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/signin",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/signup",
    component: <SignUp />,
    hidden: true,
  },
  {
    type: "collapse",
    name: "Company Sign Up",
    key: "company-sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/signup/company",
    component: <CompanySignUp />,
    hidden: true,
  },
  {
    type: "collapse",
    name: "Student Sign Up",
    key: "student-sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/signup/student",
    component: <StudentSignUp />,
    hidden: true,
  },
  {
    type: "collapse",
    name: "Reset Password",
    key: "reset-password",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/password/reset",
    component: <ResetPassword />,
    hidden: true,
  },
];

export default authRoutes;
