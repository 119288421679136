import React, { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
// @mui icons

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Overview page components
import Header from "layouts/profile/components/Header";

// Images
import MDInput from "components/MDInput";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";

function StudentProfile() {
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({ companyName: "Weg" });
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <Grid container mt={3}>
          <Grid item container xs={12} justifyContent="flex-end">
            {!isEdit ? (
              <MDButton
                variant="text"
                color={"dark"}
                onClick={() => setIsEdit(true)}
              >
                <Icon>edit</Icon>&nbsp;Editar datos
              </MDButton>
            ) : (
              <MDButton
                variant="gradient"
                color="success"
                onClick={() => setIsEdit(false)}
              >
                Guardar
              </MDButton>
            )}
          </Grid>
          {isEdit ? (
            <>
              <Grid item mt={1} xs={12} md={7}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Nombre"
                    fullWidth
                    value="Agostina"
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Apellido"
                    fullWidth
                    value="Lanzetti"
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput type="text" label="Legajo" fullWidth value="14044" />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput type="text" label="DNI" fullWidth value="41000729" />
                </MDBox>
              </Grid>
              <Grid container mt={1}>
                <Grid item xs={12}>
                  <MDTypography variant="h6" fontWeight="medium" color="dark">
                    Datos personales
                  </MDTypography>
                </Grid>
                <Grid item mt={1} xs={12} md={7}>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Cuil"
                      fullWidth
                      value="27-41000729-6"
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="date"
                      label="Fecha de nacimiento"
                      fullWidth
                      value="1998-06-10"
                    />
                  </MDBox>
                  <MDInput
                    type="text"
                    label="Teléfono"
                    fullWidth
                    value="15655487"
                  />
                </Grid>
                <Grid item mt={1} xs={12} md={7}>
                  <MDTypography variant="button">Género</MDTypography>
                  <RadioGroup
                    row
                    name="gender-radio-buttons-group"
                    value="female"
                  >
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Mujer"
                    />
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Hombre"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label="No binario"
                    />
                  </RadioGroup>
                </Grid>

                <Grid item mt={1} xs={12} md={7}>
                  <MDTypography variant="button">Estado civil</MDTypography>
                  <RadioGroup
                    row
                    name="civil-status-radio-buttons-group"
                    value="single"
                  >
                    <FormControlLabel
                      value="single"
                      control={<Radio />}
                      label="Soltero/a"
                    />
                    <FormControlLabel
                      value="married"
                      control={<Radio />}
                      label="Casado/a"
                    />
                    <FormControlLabel
                      value="divorced"
                      control={<Radio />}
                      label="Divorciado/a"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item mt={1} xs={12} md={7}>
                  <MDTypography variant="button">
                    Redes sociales que utiliza
                  </MDTypography>
                </Grid>
                <Grid item mt={1} xs={12} md={7}>
                  <Checkbox checked name="instagram" />
                  <MDTypography variant="button" pr={2}>
                    Instagram
                  </MDTypography>
                  <Checkbox checked name="facebook" />
                  <MDTypography variant="button" pr={2}>
                    Facebook
                  </MDTypography>
                  <Checkbox checked={false} name="twitter" />
                  <MDTypography variant="button" pr={2}>
                    Twitter
                  </MDTypography>
                  <Checkbox checked name="linkedin" />
                  <MDTypography variant="button" pr={2}>
                    Linkedin
                  </MDTypography>
                </Grid>
              </Grid>
              <Grid container mt={3}>
                <Grid item xs={12}>
                  <MDTypography variant="h6" fontWeight="medium" color="dark">
                    Datos de locación
                  </MDTypography>
                </Grid>
                <Grid item mt={2} xs={12} md={7}>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Domicilio"
                      fullWidth
                      value="9 de julio 1200"
                    />
                  </MDBox>
                  <MDBox>
                    <RadioGroup
                      row
                      name="ownership-radio-buttons-group"
                      value={false}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Es propietario"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="Es inquilino"
                      />
                    </RadioGroup>
                  </MDBox>
                </Grid>
                <Grid item container mt={0} mb={2} spacing={3}>
                  <Grid item xs={12} md={4}>
                    <MDInput
                      type="text"
                      label="Localidad"
                      fullWidth
                      value="San Francisco"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MDInput
                      type="text"
                      label="Provincia"
                      fullWidth
                      value="Córdoba"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MDInput
                      type="number"
                      label="Código Postal"
                      fullWidth
                      value="2400"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={3}>
                <Grid mt={3} item xs={12}>
                  <MDTypography variant="h6" fontWeight="medium" color="dark">
                    Datos académicos de nivel medio
                  </MDTypography>
                </Grid>
                <Grid item container mt={1} xs={12} spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      type="text"
                      label="Entidad estudiantil de nivel medio"
                      fullWidth
                      value="Colegio Superior San Martín"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDInput
                      type="text"
                      label="Título de nivel medio"
                      fullWidth
                      value="Bachiller en Economía y Administración"
                    />
                  </Grid>
                </Grid>
                <Grid item container mt={0} xs={12} spacing={3}>
                  <Grid item xs={12} md={4}>
                    <MDInput
                      type="text"
                      label="Localidad de la entidad"
                      fullWidth
                      value="San Francisco"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MDInput
                      type="text"
                      label="Provincia de la entidad"
                      fullWidth
                      value="Córdoba"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MDInput
                      type="number"
                      label="Código Postal de la entidad"
                      fullWidth
                      value="2400"
                    />
                  </Grid>
                </Grid>
                <Grid mt={3} item xs={12}>
                  <MDTypography variant="h6" fontWeight="medium" color="dark">
                    Datos académicos de nivel superior
                  </MDTypography>
                </Grid>
                <Grid item container mt={0} xs={12} spacing={3}>
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Carrera de grado en curso
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={1}
                        label="Carrera de grado en curso"
                      >
                        <MenuItem value={1}>
                          Ingeniería en Sistemas de Información
                        </MenuItem>
                        <MenuItem value={2}>Ingeniería Química</MenuItem>
                        <MenuItem value={3}>Ingeniería Electrónica</MenuItem>
                        <MenuItem value={3}>
                          Ingeniería Electromecánica
                        </MenuItem>
                        <MenuItem value={3}>Ingeniería Industrial</MenuItem>
                        <MenuItem value={3}>
                          Licenciatura en Administración Rural
                        </MenuItem>
                        <MenuItem value={3}>
                          Tecnicatura en Programación
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <MDInput
                      type="number"
                      label="Cantidad de materias aprobadas"
                      fullWidth
                      value="42"
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <MDInput
                      type="number"
                      label="Año de ingreso"
                      fullWidth
                      value="2016"
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <MDInput
                      type="number"
                      label="Año de curso actual"
                      fullWidth
                      value="2022"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={3}>
                <Grid mt={3} item xs={12}>
                  <MDTypography variant="h6" fontWeight="medium" color="dark">
                    Datos de salud
                  </MDTypography>
                </Grid>
                <Grid item mt={0} xs={12} md={7}>
                  <MDTypography variant="button">
                    Tiene obra social
                  </MDTypography>
                  <RadioGroup
                    row
                    name="health-insurance-radio-buttons-group"
                    value={true}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Si"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                  <MDBox mt={1} mb={4}>
                    <MDInput
                      type="text"
                      label="Obra social"
                      fullWidth
                      value="Swiss Medical"
                    />
                  </MDBox>
                  <MDBox mt={2} mb={2}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Grupo sanguíneo
                      </InputLabel>
                      <Select
                        labelId="blood-type-select"
                        id="blood-type-select"
                        value={2}
                      >
                        <MenuItem value={1}>0 negativo</MenuItem>
                        <MenuItem value={2}>0 positivo</MenuItem>
                        <MenuItem value={3}>A negativo</MenuItem>
                        <MenuItem value={4}>A positivo</MenuItem>
                        <MenuItem value={5}>B negativo</MenuItem>
                        <MenuItem value={6}>B positivo</MenuItem>
                        <MenuItem value={7}>AB negativo</MenuItem>
                        <MenuItem value={8}>AB positivo</MenuItem>
                      </Select>
                    </FormControl>
                  </MDBox>
                  <MDTypography variant="button">
                    Es donante de órganos
                  </MDTypography>
                  <RadioGroup
                    row
                    name="health-insurance-radio-buttons-group"
                    value={false}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Si"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid item mt={1} xs={12} md={7}>
                <MDBox mb={2}>
                  <TextField
                    label="Nombre"
                    fullWidth
                    defaultValue="Agostina"
                    disabled
                  />
                </MDBox>
                <MDBox mb={2}>
                  <TextField
                    label="Apellido"
                    fullWidth
                    defaultValue="Lanzetti"
                    disabled
                  />
                </MDBox>

                <MDBox mb={2}>
                  <TextField
                    label="Legajo"
                    fullWidth
                    defaultValue="14044"
                    disabled
                  />
                </MDBox>
                <MDBox mb={2}>
                  <TextField
                    label="DNI"
                    fullWidth
                    disabled
                    defaultValue="41000729"
                  />
                </MDBox>
              </Grid>
              <Grid container mt={1}>
                <Grid item xs={12}>
                  <MDTypography variant="h6" fontWeight="medium" color="dark">
                    Datos personales
                  </MDTypography>
                </Grid>
                <Grid item mt={1} xs={12} md={7}>
                  <MDBox mb={2}>
                    <TextField
                      label="Cuil"
                      fullWidth
                      defaultValue="27-41000729-6"
                      disabled
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <TextField
                      type="date"
                      label="Fecha de nacimiento"
                      fullWidth
                      disabled
                      value="1998-06-10"
                    />
                  </MDBox>
                  <MDBox mb={3}>
                    <TextField
                      label="Teléfono"
                      fullWidth
                      disabled
                      defaultValue="15655487"
                    />
                  </MDBox>
                </Grid>
                <Grid item mt={1} xs={12} md={7}>
                  <MDTypography variant="button">Género</MDTypography>
                  <RadioGroup
                    row
                    name="gender-radio-buttons-group"
                    value="female"
                  >
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Mujer"
                    />
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Hombre"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label="No binario"
                    />
                  </RadioGroup>
                </Grid>

                <Grid item mt={1} xs={12} md={7}>
                  <MDTypography variant="button">Estado civil</MDTypography>
                  <RadioGroup
                    row
                    name="civil-status-radio-buttons-group"
                    value="single"
                  >
                    <FormControlLabel
                      value="single"
                      control={<Radio />}
                      label="Soltero/a"
                    />
                    <FormControlLabel
                      value="married"
                      control={<Radio />}
                      label="Casado/a"
                    />
                    <FormControlLabel
                      value="divorced"
                      control={<Radio />}
                      label="Divorciado/a"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item mt={1} xs={12} md={7}>
                  <MDTypography variant="button">
                    Redes sociales que utiliza
                  </MDTypography>
                </Grid>
                <Grid item mt={1} xs={12} md={7}>
                  <Checkbox checked name="instagram" />
                  <MDTypography variant="button" pr={2}>
                    Instagram
                  </MDTypography>
                  <Checkbox checked name="facebook" />
                  <MDTypography variant="button" pr={2}>
                    Facebook
                  </MDTypography>
                  <Checkbox checked={false} name="twitter" />
                  <MDTypography variant="button" pr={2}>
                    Twitter
                  </MDTypography>
                  <Checkbox checked name="linkedin" />
                  <MDTypography variant="button" pr={2}>
                    Linkedin
                  </MDTypography>
                </Grid>
              </Grid>
              <Grid container mt={3}>
                <Grid item xs={12}>
                  <MDTypography variant="h6" fontWeight="medium" color="dark">
                    Datos de locación
                  </MDTypography>
                </Grid>
                <Grid item mt={2} xs={12} md={7}>
                  <MDBox mb={2}>
                    <TextField
                      label="Domicilio"
                      fullWidth
                      disabled
                      defaultValue="9 de julio 1200"
                    />
                  </MDBox>
                  <MDBox>
                    <RadioGroup
                      row
                      name="ownership-radio-buttons-group"
                      value={false}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Es propietario"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="Es inquilino"
                      />
                    </RadioGroup>
                  </MDBox>
                </Grid>
                <Grid item container mt={0} mb={2} spacing={3}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Localidad"
                      fullWidth
                      disabled
                      defaultValue="San Francisco"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Provincia"
                      disabled
                      fullWidth
                      defaultValue="Córdoba"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Código Postal"
                      disabled
                      fullWidth
                      defaultValue="2400"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={3}>
                <Grid mt={3} item xs={12}>
                  <MDTypography variant="h6" fontWeight="medium" color="dark">
                    Datos académicos de nivel medio
                  </MDTypography>
                </Grid>
                <Grid item container mt={1} xs={12} spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Entidad estudiantil de nivel medio"
                      fullWidth
                      defaultValue="Colegio Superior San Martín"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Título de nivel medio"
                      fullWidth
                      defaultValue="Bachiller en Economía y Administración"
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid item container mt={0} xs={12} spacing={3}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Localidad de la entidad"
                      fullWidth
                      defaultValue="San Francisco"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Provincia de la entidad"
                      fullWidth
                      defaultValue="Córdoba"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Código Postal de la entidad"
                      fullWidth
                      defaultValue="2400"
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid mt={3} item xs={12}>
                  <MDTypography variant="h6" fontWeight="medium" color="dark">
                    Datos académicos de nivel superior
                  </MDTypography>
                </Grid>
                <Grid item container mt={0} xs={12} spacing={3}>
                  <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Carrera de grado en curso
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={1}
                        label="Carrera de grado en curso"
                        disabled
                      >
                        <MenuItem value={1}>
                          Ingeniería en Sistemas de Información
                        </MenuItem>
                        <MenuItem value={2}>Ingeniería Química</MenuItem>
                        <MenuItem value={3}>Ingeniería Electrónica</MenuItem>
                        <MenuItem value={3}>
                          Ingeniería Electromecánica
                        </MenuItem>
                        <MenuItem value={3}>Ingeniería Industrial</MenuItem>
                        <MenuItem value={3}>
                          Licenciatura en Administración Rural
                        </MenuItem>
                        <MenuItem value={3}>
                          Tecnicatura en Programación
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      label="Cantidad de materias aprobadas"
                      fullWidth
                      defaultValue="42"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      label="Año de ingreso"
                      fullWidth
                      value="2016"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      label="Año de curso actual"
                      fullWidth
                      defaultValue="2022"
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={3}>
                <Grid mt={3} item xs={12}>
                  <MDTypography variant="h6" fontWeight="medium" color="dark">
                    Datos de salud
                  </MDTypography>
                </Grid>
                <Grid item mt={0} xs={12} md={7}>
                  <MDTypography variant="button">
                    Tiene obra social
                  </MDTypography>
                  <RadioGroup
                    row
                    name="health-insurance-radio-buttons-group"
                    value={true}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Si"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                  <MDBox mt={1} mb={4}>
                    <TextField
                      type="text"
                      label="Obra social"
                      fullWidth
                      defaultValue="Swiss Medical"
                      disabled
                    />
                  </MDBox>
                  <MDBox mt={2} mb={2}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Grupo sanguíneo
                      </InputLabel>
                      <Select
                        labelId="blood-type-select"
                        id="blood-type-select"
                        value={2}
                        disabled
                      >
                        <MenuItem value={1}>0 negativo</MenuItem>
                        <MenuItem value={2}>0 positivo</MenuItem>
                        <MenuItem value={3}>A negativo</MenuItem>
                        <MenuItem value={4}>A positivo</MenuItem>
                        <MenuItem value={5}>B negativo</MenuItem>
                        <MenuItem value={6}>B positivo</MenuItem>
                        <MenuItem value={7}>AB negativo</MenuItem>
                        <MenuItem value={8}>AB positivo</MenuItem>
                      </Select>
                    </FormControl>
                  </MDBox>
                  <MDTypography variant="button">
                    Es donante de órganos
                  </MDTypography>
                  <RadioGroup
                    row
                    name="health-insurance-radio-buttons-group"
                    value={false}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Si"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Header>
    </DashboardLayout>
  );
}

export default StudentProfile;
