import localStorageService from "./localStorageService";

class AuthStorage {
  setSession(token, role) {
    localStorageService.set("auth-token", token);
    localStorageService.set("role", role);
  }

  getSession() {
    return localStorageService.get("auth-token");
  }

  getSessionUserName() {
    return localStorageService.get("sae-user-name");
  }

  getSessionUserRole() {
    return localStorageService.get("sae-user-role");
  }

  deleteSession() {
    localStorageService.remove("auth-token");
    localStorageService.remove("sae-user-role");
    localStorageService.remove("sae-user-name");
  }
}

export default new AuthStorage();
